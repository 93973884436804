import *  as baseService  from "./BaseService";

const buildRequest = (text, pageNumber, pageSize, countries, certificateNumbers, species, excludedApprovalNumbers) => {
    
    if(countries!= undefined){
        if(!Array.isArray(countries)){
          countries = [countries];
        }
    }
    else{
        certificateNumbers = []
    }

    if(species!= undefined){
        if(!Array.isArray(species)){
            species = [species];
        }
    }
    else{
        species = []
    }
    
    if(certificateNumbers!= undefined){
        if(!Array.isArray(certificateNumbers)){
            certificateNumbers = [certificateNumbers];
        }
    }
    else{
        certificateNumbers = []
    }

    var request = {
        text:  text,
        pageNumber: pageNumber?? 1,
        pageSize: pageSize?? 10,
        certificateNumbers: certificateNumbers?? [],
        establishmentTypeNumber: 0,
        species: species?? [],
        countries: countries?? [],
        excludedApprovalNumbers: excludedApprovalNumbers?? []
    }
    return request;
};
const getAllPaged = (params) => {
    return baseService.getApiDataWithParams(`/Establishments/getAllPaged`, params);
};
const getEstablishmentsDetailsByApprovalNumber = (approvalNumber) => {
    return baseService.postApiData(`/Establishments/getEstablishmentsDetailsByApprovalNumber?approvalNumber=${approvalNumber}`);
};
const getEstablishmentsDetails = (ids) => {
    return baseService.postApiData(`/Establishments/getEstablishmentsDetails`, ids);
};
const getSites = (data) => {
    return baseService.postApiData(`/Establishments/getSites`, data);
};
const getColdStores = (data) => {
    return baseService.postApiData(`/Establishments/getColdStores`, data);
};
const getSlaughterhouses = (data) => {
    return baseService.postApiData(`/Establishments/getSlaughterhouses`, data);
};
const getCuttingPlants = (data) => {
    return baseService.postApiData(`/Establishments/getCuttingPlants`, data);
};
const getFisheries = (data) => {
    return baseService.postApiData(`/Establishments/getFisheries`, data);
};
const getEggEstablishments = (data) => {
    return baseService.postApiData(`/Establishments/getEggEstablishments`, data);
};
const getDairyEstablishments = (data) => {
    return baseService.postApiData(`/Establishments/getDairyEstablishments`, data);
};
const getRewrappingEstablishment = (data) => {
    return baseService.postApiData(`/Establishments/getRewrappingEstablishment`, data);
};
const getBivalveEstablishments = (data) => {
    return baseService.postApiData(`/Establishments/getBivalveEstablishments`, data);
};
const getMeatEstablishments = (data) => {
    return baseService.postApiData(`/Establishments/getMeatEstablishments`, data);
};
const getCollagenAndGelatineEstablishments = (data) => {
    return baseService.postApiData(`/Establishments/getCollagenAndGelatineEstablishments`, data);
};
const getHoneyEstablishments = (data) => {
    return baseService.postApiData(`/Establishments/getHoneyEstablishments`, data);
};
const getScrapeLinks = (params) => {
    return baseService.getApiDataWithParams(`/Establishments/getScrapeLinks`, params);
};
const scrapeSpecificData = (classificationCode, countryCode) => {
    return baseService.postApiData(`/Establishments/ScrapeSpecificData?classificationCode=${classificationCode}&countryCode=${countryCode}`);
};
const scrapeAllLinks = () => {
    return baseService.postApiData(`/Establishments/scrapeAllLinks`);
};

const EstablishmentsService = {
    getAllPaged,
    getEstablishmentsDetailsByApprovalNumber,
    getEstablishmentsDetails,
    getRewrappingEstablishment,
    getSites,
    getColdStores,
    getSlaughterhouses,
    getCuttingPlants,
    getFisheries,
    getEggEstablishments,
    getDairyEstablishments,
    getCuttingPlants,
    getBivalveEstablishments,
    getMeatEstablishments,
    getCollagenAndGelatineEstablishments,
    getHoneyEstablishments,
    buildRequest,
    getScrapeLinks,
    scrapeSpecificData,
    scrapeAllLinks
};

export default EstablishmentsService;