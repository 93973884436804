import React, {useState, useEffect} from 'react';
import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import { useFormikContext } from 'formik';
import ScrollToTop from "../../../../../components/ScrollToTop"
import DailyAttestationTemplate from "./DailyAttestationTemplate";
import SigningComponent from '../../../../../components/SigningComponent';

export default function DailyAttestationReview(props) {
    
  const { values: formValues, setFieldValue } = useFormikContext();
  const {
        formField: {
            trackingRef,
            birdFluCheck,
            uniqueCode,
            batchNumbers,
            batchNumberRange,
            commercialNumber,
            deliveryDate,
            numberOfPackages,
            numberOfUnits,
            numberOfCases,
            abattoirs,
            farmPost,
            farmPostEgg,
            slaughterDate,
            slaughterDates,
            abattoirName,
            cuttingPlantApprovalNumbers,
            poultryCuttingPlantApprovalNumbers,
            abattoirApprovalNumbers,
            poultryMeatArrivalDate,
            dairyEstablishments,
            grossWeight,
            netWeight,
            netWeightTotal,
            grossWeightTotal,
            manufacturerName,
            originAnimal,
            dateCollectionProduction,
            notes,
            establishmentsSlaughterhouses,
            establishmentsCuttingPlants,
            establishmentsDairy,
        },
        uniqueRef,
        user,
        forwardedRef,
        generatedSADocument
    } = props;

    const [isPdfGenerating, setPdfGenerating] = useState(false);

    //Check is PDF is being generated in backround, ready for signing
    useEffect(()=>{ 
        generatedSADocument==""? setPdfGenerating(true) : setPdfGenerating(false) 
    },[generatedSADocument]);

    return (
        <Row>
            <Col xl={11}>
                <ScrollToTop/>
                <DailyAttestationTemplate 
                    birdFluCheck={birdFluCheck}
                    forwardedRef={forwardedRef}
                    uniqueRef={uniqueRef}
                    trackingRef={trackingRef}
                    user={user}
                    manufacturerName={formValues.manufacturerName}
                    dailyAttestationData={formValues.dailyAttestationData}
                    batchNumbers={batchNumbers}
                    batchNumberRange={batchNumberRange}
                    commercialNumber={commercialNumber}
                    deliveryDate={deliveryDate}
                    numberOfPackages={numberOfPackages}
                    numberOfUnits={numberOfUnits}
                    numberOfCases={numberOfCases}
                    abattoirs={abattoirs}
                    cuttingPlantApprovalNumbers={cuttingPlantApprovalNumbers}
                    dairyEstablishments={dairyEstablishments}
                    farmPost={farmPost}
                    farmPostEgg={farmPostEgg}
                    abattoirName={abattoirName}
                    slaughterDate={slaughterDate}
                    slaughterDates={slaughterDates}
                    poultryCuttingPlantApprovalNumbers={poultryCuttingPlantApprovalNumbers}
                    abattoirApprovalNumbers={abattoirApprovalNumbers}
                    poultryMeatArrivalDate={poultryMeatArrivalDate}
                    grossWeight={grossWeight}
                    grossWeightTotal={grossWeightTotal}
                    netWeight={netWeight}
                    netWeightTotal={netWeightTotal}
                    uniqueCode={uniqueCode}
                    originAnimal={originAnimal}
                    dateCollectionProduction={dateCollectionProduction}
                    notes={notes}
                    establishmentsSlaughterhouses={establishmentsSlaughterhouses}
                    establishmentsCuttingPlants={establishmentsCuttingPlants}
                    establishmentsDairy={establishmentsDairy}
                />

                <Card border="light" className="bg-white shadow-sm mb-4 p-3">
                    <Card.Body>
                        <h4>Review Daily Attestation Declaration</h4>
                        <p>
                            Now you have completed the Daily Attestation, click the button below to review and sign the document.<br />
                            Please Note: Clicking the button below will redirect you to our secure document signing provider.
                        </p>
                        <SigningComponent
                            document={generatedSADocument}
                            ready={isPdfGenerating}
                            buttonText = "Review & Sign Declaration"
                        /> 
                    </Card.Body>
                </Card>
            </Col>
        </Row>
  );
}
