import { faFilter, faPlus, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Modal, Row, Table } from "@themesberg/react-bootstrap";
import { Form as FormikForm, Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import AttestationService from "../../../../services/AttestationService";
import FormController from "../../../common/FormController";
import Paging from "../../../common/Paging";
import DailyAttestationProductListTable from "./FormComponents/DailyAttestationProductListTable";

export default function DailyAttestationProductSelect(props) {

    const [count, setCount] = useState(0);
    const { totalPages = count, size = "md", withIcons = false, disablePrev = false } = props;
    const { values: formValues, setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
    const {
        formField: {
            trackingRef,
            commercialNumber,
            manufacturer,
            deliveryDate,
        },
        cloneData,
        user,
        manufacturers,
        manufacturerRestricted
    } = props;

    const [showModalAdd, setShowModalAdd] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [activePage, setActivePage] = React.useState(1);
    const [searchTitle, setSearchTitle] = useState("");
    const [dailyAttestationData2, setDailyAttestationData] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [loaded, setLoaded] = useState(false);

    const initialValues = {
        search: '',
        productsSelected: []
    }

    const getFilterParams = (searchTitle, activePage, pageSize) => {
        let params = {};
        if (searchTitle) {
            params["searchTerms"] = searchTitle;
        }
        if (activePage) {
            params["page"] = activePage;
        }
        if (pageSize) {
            params["size"] = pageSize;
        }
        return params;
    };

    const retrieveAttestationProducts = () => {
        let params = getFilterParams(searchTitle, activePage, pageSize);
        getAttestationProducts(params);
    };

    function onSubmit(values, { setSubmitting, resetForm }) {
        setTimeout(() => {
            setActivePage(1);
            retrieveAttestationProducts();
            setSubmitting(false);
        }, 1000);
    }

    useEffect(() => {
        retrieveAttestationProducts();
    }, [activePage, pageSize]);

    //Check Claims if ManufacturerId is required
    useEffect(() => {
        if(user.manufacturerId == undefined || user.manufacturerId == null || user.manufacturerId == ""){
            formValues.requireManufacturer = true;
        } else {
            if (user.manufacturerId.length > 1) {
                formValues.requireManufacturer = true;
                return;
            }
            formValues.requireManufacturer = false;
            formValues.manufacturerId = user.manufacturerId[0];
            setFieldValue("manufacturerId", user.manufacturerId[0]);
            formValues.manufacturerName = manufacturerRestricted?.manufacturerName;
        }
    }, [user, manufacturerRestricted]);

    useEffect(() => {
        if (cloneData !== null && cloneData !== undefined) {
            formValues.productsSelected = cloneData?.productsSelected;
        }
    }, [cloneData]);

    //Map ManufacturerId to form values when chosen from list
    useEffect(() => {
        if (formValues.manufacturer !== null && formValues.manufacturer !== undefined) {
            if (formValues.manufacturer.value !== undefined) {
                setFieldValue("manufacturerId", formValues.manufacturer.value);
                setFieldValue("manufacturerName", formValues.manufacturer.label);
            }
        }
    }, [formValues.manufacturer]);

    //Paging Functions
    const onPrevItem = () => {
        const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
        setActivePage(prevActiveItem);
    };
    const onPageNumberClick = (number) => {
        setActivePage(number);
    };

    const onNextItem = (totalPages) => {
        const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
        setActivePage(nextActiveItem);
    };

    const handleCloseModalAdd = () => setShowModalAdd(false);

    const _handleRemoveProduct = (id) => {
        const arr = formValues.productsSelected.filter((item) => item.id !== id);
        setFieldValue("productsSelected", arr);
    }

    const getAttestationProducts = (params) => {
        setLoaded(false);
        AttestationService.getDailyAttestationProducts(params)
            .then((response) => {
                const { data, totalPages, totalRecords } = response.data;
                setTotalItems(totalRecords);
                setDailyAttestationData(data);
                setCount(totalPages);
                setLoaded(true);
            })
            .catch((e) => {
                setLoaded(true);
                console.log(e);
            });
    };

    return (
        <Row className="justify-content-left mt-4">
            <Col xl={11}>

                <Card border="light" className="bg-white shadow-sm mb-4 p-3">
                    <Card.Body>
                        <div className="mb-4">
                            {formValues.requireManufacturer &&
                                <FormController
                                    control="multiselect"
                                    placeholder="Select a manufacturer"
                                    label={manufacturer.label}
                                    name={manufacturer.name}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.manufacturer}
                                    error={errors.manufacturer}
                                    options={manufacturers}
                                    isMulti={false}
                                    isClearable={true}
                                    isrequired="true"
                                    col={8}
                                />
                            }

                            <FormController
                                control="dateInput"
                                type="text"
                                label={deliveryDate.label}
                                name={deliveryDate.name}
                                isrequired={"true"}
                                col={5}
                            />
                            <FormController
                                control="input"
                                type="text"
                                label={commercialNumber.label}
                                name={commercialNumber.name}
                                isrequired="true"
                                col={4}
                            />
                        </div>

                        <h5 className="text-title mb-2">Product Selection</h5>

                        <label>Which products need to be included in this daily attestation?</label>
                        <p className="mb-0">Only products that are GEFS and have current valid attestations are eligible for inclusion in daily attestations</p>

                        {formValues.productsSelected?.length > 0 &&
                            (
                                <section className="mt-4">
                                    <label>Selected products</label>
                                    <p><strong>{formValues.productsSelected.length}</strong> item(s) selected.</p>
                                    <Table hidden={!loaded} hover responsive className="align-items-center table-flush">
                                        <thead>
                                            <tr>
                                                <th className="border-bottom">Product name / Attestation Unique Reference Number</th>
                                                <th className="border-bottom">EAN</th>
                                                <th className="border-bottom">Valid Dates</th>
                                                <th className="border-bottom">Batch numbers</th>
                                                <th className="border-bottom"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formValues.productsSelected.map((x, i) =>
                                                <tr key={`selected-${x.id}`}>
                                                    <td>
                                                        <span className="fw-normal">
                                                            {x.productName}<br />{x.uniqueCode}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fw-normal">
                                                            {x.ean}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fw-normal">
                                                            {x.validDates}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fw-normal">
                                                            {x.batchNumbers}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className="d-inline  ms-3">
                                                            <Button
                                                                variant="link"
                                                                size="md"
                                                                onClick={() => _handleRemoveProduct(x.id)}
                                                            >
                                                                <FontAwesomeIcon icon={faMinusSquare} className="me-2 text-danger" />
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </section>
                            )
                        }
                        <Button onClick={() => setShowModalAdd(true)} variant="light" size="sm" className="text-dark my-4 ">
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            {formValues.productsSelected?.length > 0 ? <span>Change Selections</span> : <span>Select Product Attestations</span>}
                        </Button>

                        {/* //Modal - Start*/}
                        <Modal size="xl" as={Modal.Dialog} centered show={showModalAdd} onHide={handleCloseModalAdd}>
                            <Modal.Header>
                                <Modal.Title className="h6">Select products to add</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleCloseModalAdd} />
                            </Modal.Header>
                            <Modal.Body>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                >
                                    {({ isSubmitting, setFieldValue }) => (
                                        <FormikForm>
                                            <Row className="mb-3">
                                                <Col md={8}>
                                                    <FormController
                                                        control="input"
                                                        type="text"
                                                        onChange={(e) => {
                                                            setFieldValue('search', e.target.value);
                                                            setSearchTitle(e.target.value);
                                                        }}
                                                        placeholder="Search by product name, code, EAN or support health attestation number"
                                                        name="search"
                                                    />
                                                </Col>
                                                <Col xs={12} sm={4} lg={4} >
                                                    <div className="form-group">
                                                        <Button variant="light" type="submit" disabled={isSubmitting}>
                                                            <FontAwesomeIcon icon={faFilter} />{isSubmitting ? "Please wait..." : "Filter"}
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </FormikForm>
                                    )}
                                </Formik >

                                <DailyAttestationProductListTable
                                    selections={formValues.productsSelected}
                                    loaded={loaded}
                                    attestationRequests={dailyAttestationData2}
                                />

                                <Paging
                                    activePage={activePage}
                                    size={size}
                                    pageSize={pageSize}
                                    totalPages={totalPages}
                                    totalItems={totalItems}
                                    onPrevItem={onPrevItem}
                                    onNextItem={onNextItem}
                                    onPageNumberClick={onPageNumberClick}
                                    withIcons={withIcons}
                                    handleChange={(selectedOption) => {
                                        const newSize = parseInt(selectedOption.value, 10);
                                        setPageSize(newSize);
                                        setActivePage(1);
                                    }}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    onClick={() => handleCloseModalAdd()}
                                    variant="primary"
                                    size="md"
                                    className="mx-auto"
                                >
                                    Confirm Product Selection
                                </Button>
                            </Modal.Footer>

                        </Modal>
                        {/* //Modal - End */}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}
