import React, { useState } from "react";
import moment from "moment";
import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import FormController from "../../common/FormController";
import { MultiSelectField } from "../../common/MultiSelect";
import { useFormikContext, FieldArray } from "formik";
import AttestationBatchRange from "./AttestationBatchRange";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const PrettyPrintJson = ({ data }) => (
  <div>
    <pre>{JSON.stringify(data, null, 2)}</pre>
  </div>
);

//Section A Sections

export const SectionANotice = () => {
  return (
    <div>
      <h6 className="mb-4 attestation-notice font-weight-bolder">
        IMPORTANT NOTE: THIS SUPPORT ATTESTATION IS NOT AN OFFICIAL EXPORT
        CERTIFICATE. It is to be used solely to support EU export certification
        of specific product categories in groupage consignments to the EU in
        accordance with guidance issued by Defra/APHA
      </h6>
    </div>
  );
};

export const SectionAProductInfo = (props) => {
  const { product } = props;
  return (
    <Col md={12} className="mt-2">
      <h6 className="mb-3">2. Description of the product(s)</h6>
      <ul className="list-unstyled">
        <li>
          Name of Product: <strong>{product.productName}</strong>
        </li>
        <li>
          Product code: <strong>{product.productCode}</strong>
        </li>
        <li>
          EAN: <strong>{product.ean}</strong>
        </li>
      </ul>
    </Col>
  );
};

export const SectionAStatementNonGEFS = (props) => {
  const { uniqueRef, user, manufacturer, organisation, isGEFS } = props;
  return (
    <Row className="mt-4 attestation-statement">
      <Col md={12}>
        <div className="attestation-title text-center pb-5">
          <h2>
            Veterinary Support Health Attestation for the Movement within the
            United Kingdom of Animal Products (Excluding Germinal Products)
          </h2>
          <p>
            This Support Health Attestation (SHA) is NOT an Official Document or
            an Export Certificate:
            <br />
            Support Health Attestation Number: {uniqueRef}
          </p>
        </div>
        <p>
          I <strong>{user ? user.fullName : ""}</strong>, of{" "}
          <strong>{user.practiceName}</strong> sign this attestation based on
          the guidance notes of ET 199 from APHA vet gateway.
        </p>
      </Col>
    </Row>
  );
};

export const SectionAStatement = (props) => {
  const { uniqueRef, user, manufacturer, organisation, isGEFS } = props;
  return (
    <Row className="mt-4 attestation-statement">
      <Col md={12}>
        <div>
          <p>
            I <strong>{user ? user.fullName : ""}</strong>, of{" "}
            <strong>{manufacturer.manufacturerName}</strong> have authority and
            responsibility to sign this declaration on behalf of this supplying
            company.
          </p>
          <p>
            I hereby declare that the details in sections A and B below includes
            a complete list of the products of animal origin contained within
            the products to which this Support Attestation relates supplied to{" "}
            <strong>
              {organisation.name}, {organisation.fullAddress}.
            </strong>
          </p>
          <p>
            I confirm that the information within this Support Attestation is
            correct and that no changes will be made to affect its validity
            prior to its date of expiry.
          </p>
          <p>
            I will ensure that the{" "}
            {isGEFS ? (
              <>
                <strong>Registered veterinarian</strong> signing the Registered
                veterinary declaration and the exporter listed above are
              </>
            ) : (
              "the exporter listed above is"
            )}{" "}
            immediately informed of any changes are made that affect the
            validity of this document and/or if I leave the employment of the
            supplying company detailed above. I understand that in such cases
            this Support Attestation will immediately become null and void.
          </p>
          <p>
            I understand that supplying false or misleading declarations that
            will be relied upon by the exporter in respect of the verifications
            provided in the relevant export health certificate is an offence and
            may result in rejection of the exported product and immediate
            removal of the exporter from the Groupage Export Facilitation Scheme
            as well as risk of liability for costs incurred.
          </p>
          <p>
            I will ensure that each consignment of products sent to the export
            depot that is covered by this support attestation is accompanied by
            a declaration signed on behalf of the supplying company and stating
            that "The evidence required to facilitate export of the products in
            this consignment has been provided in Support Attestation{" "}
            <strong>{uniqueRef}</strong>. No changes have been made that affect
            the validity of the information provided in this Support
            Attestation."
          </p>
          <p>
            I will produce on request consignment specific documents separate to
            this support attestation to assist in the production of export
            health certificates.
          </p>
        </div>
      </Col>
    </Row>
  );
};

export const SectionAProductDetails = (props) => {
  const { platform, site } = props;
  return (
    <Row className="mt-2 attestation-productdetails">
      <Col md={12}>
        <h4>Section A Details of Products</h4>
        <h6>1. Origin and Destination</h6>
        <p>
          a) Address and, if available,{" "}
          {platform.establishmentNumberTypeName?.toLowerCase()} of the
          establishment(s) from which the consignment will be dispatched (e.g.
          supplier):
          <br />
          <strong>
            {site.siteName}, {site.fullAddress}.{" "}
            {site.establishmentNumberTypeName}: {site.establishmentNumber}
          </strong>
        </p>
        <p>
          b) Address and, if available,{" "}
          {site.establishmentNumberTypeName?.toLowerCase()} of the
          establishment(s) to which the consignment will be dispatched (e.g.
          exporting depots):
          <br />
          <strong>
            {platform.platformName}, {platform.fullAddress}.{" "}
            {platform.establishmentNumberTypeName}:{" "}
            {platform.establishmentNumber}
          </strong>
        </p>
      </Col>
    </Row>
  );
};

//Common Components

export const Urn = (props) => {
  const { uniqueRef, customUniqueRef, isReadOnly, isGEFS } = props;
  return (
    <Card
      border="dark"
      className="attestation-urn bg-white card-border-black shadow-none mb-4 p-3 text-center"
    >
      <Card.Body className="text-center">
        <label>UNIQUE DOCUMENT REFERENCE NUMBER:</label>
        <h4>{uniqueRef}</h4>
        {isReadOnly && customUniqueRef != null && (
          <h6>Our reference number: {customUniqueRef}</h6>
        )}
        {!isReadOnly && isGEFS && (
          <>
            <Row className="justify-content-md-center">
              <Col md={6}>
                <FormController
                  control="input"
                  label={customUniqueRef.label}
                  name={customUniqueRef.name}
                  className="mb-1"
                />
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export const Traceability = (props) => {
  const { traceability, isReadOnly } = props;
  return (
    <>
      {!isReadOnly ? (
        <>
          <label className="mt-3 is-required">{traceability.label}</label>
          <div className="text-muted form-text pb-2">
            {traceability.sublabel}
          </div>
          <FormController
            control="textarea"
            label={null}
            sublabel={null}
            fieldheight="100px"
            name={traceability.name}
          />
        </>
      ) : (
        <>
          <ul className="list-unstyled mb-0">
            <li>
              Traceability: <strong>{traceability}</strong>
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export const TraceabilityTable = (props) => {
  const { finalConsumer, isReadOnly, certificateNumber } = props;
  return (
    <>
      {!isReadOnly ? (
        <>
          {!certificateNumber.includes("8325") ? (
            <FormController
              control="checkbox"
              isrequired={"true"}
              className="mt-3"
              label={finalConsumer.label}
              name={finalConsumer.name}
            />
          ) : (
            <p></p>
          )}
        </>
      ) : (
        <>
          <ul className="list-unstyled">
            {!certificateNumber.includes("8325") && (
              <li>
                Final Consumer: <strong>{finalConsumer ? "Yes" : "No"}</strong>
              </li>
            )}
          </ul>
        </>
      )}
    </>
  );
};

export const CertificateFormDetails = (props) => {
  const {
    manufacturer,
    productDetail,
    certificateNumber,
    attestationData,
    isGEFS,
    site,
    temperature,
  } = props;
  return (
    <ul className="list-unstyled">
      <li>
        Supplier Name: <strong>{manufacturer.manufacturerName}</strong>
      </li>
      <li>
        Supplier Code: <strong>{manufacturer.supplierCode}</strong>
      </li>
      {/* net weight */}
      {certificateNumber.includes("8350") && (
        <>
          {attestationData[8350]?.meatData?.abattoirEstablishments && (
            <li>
              Slaugherhouse(s){" "}
              {certificateNumber.length > 1 && " (Composite 8350)"}:{" "}
              <strong>
                {attestationData[8350]?.meatData.abattoirEstablishments
                  .map((x) => x.approvalNumber)
                  .join(", ")}
              </strong>
            </li>
          )}
          {/* <li>Cutting Plant(s) {certificateNumber.length > 1 && "(Composite 8350)"} : <strong>{attestationData[8350]?.cuttingPlantEstablishments.map(x => x.approvalNumber).join(", ")}</strong></li> */}
          {attestationData[8350]?.coldStoreEstablishments?.length > 0 && (
            <li>
              Cold Store(s){certificateNumber.length > 1 && " (Composite 8350)"}
              :{" "}
              <strong>
                {attestationData[8350]?.coldStoreEstablishments
                  ?.map((x) => x.approvalNumber)
                  .join(", ")}
              </strong>
            </li>
          )}
        </>
      )}

      {certificateNumber.includes("8468") && (
        <>
          {attestationData[8468]?.coldStoreEstablishments?.length > 0 && (
            <li>
              Cold Store(s){certificateNumber.length > 1 && " (Dairy 8468)"}:{" "}
              <strong>
                {attestationData[8468]?.coldStoreEstablishments
                  ?.map((x) => x.approvalNumber)
                  .join(", ")}
              </strong>
            </li>
          )}
          {attestationData[8468]?.rewrappingEstablishments?.length > 0 && (
            <li>
              Rewrapping establishments(s)
              {certificateNumber.length > 1 && " (Dairy 8468)"}:{" "}
              <strong>
                {attestationData[8468]?.rewrappingEstablishments
                  ?.map((x) => x.approvalNumber)
                  .join(", ")}
              </strong>
            </li>
          )}
          <li>
            Identification Mark{certificateNumber.length > 1 && " (Dairy 8468)"}{" "}
            :{" "}
            <strong>
              {
                attestationData[8468]?.identificationMarkEstablishments
                  ?.approvalNumber
              }
            </strong>
          </li>
          <li>
            Manufacturing Plant{certificateNumber.length > 1 && " (Dairy 8468)"}{" "}
            :{" "}
            <strong>
              {site.approvalNumber
                ? site.approvalNumber
                : site.establishmentNumber}
            </strong>
          </li>
        </>
      )}
      {certificateNumber.includes("8384") && (
        <>
          {attestationData[8384]?.coldStoreEstablishments?.length > 1 && (
            <li>
              Cold Store(s){certificateNumber.length > 1 && "(Meat 8384)"}:{" "}
              <strong>
                {attestationData[8384]?.coldStoreEstablishments
                  ?.map((x) => x.approvalNumber)
                  .join(", ")}
              </strong>
            </li>
          )}
          <li>
            Slaughterhouse(s){certificateNumber.length > 1 && "(Meat 8384)"}:{" "}
            <strong>
              {attestationData[8384]?.abattoirEstablishments
                ?.map((x) => x.approvalNumber)
                .join(", ")}
            </strong>
          </li>
          {/* <li>Cutting Plant(s) {certificateNumber.length > 1 && "(Meat 8384)"} : <strong>{attestationData[8384]?.cuttingPlantEstablishments?.map(x => x.approvalNumber).join(", ")}</strong></li> */}
          <li>
            Identification Mark{certificateNumber.length > 1 && "(Meat 8384)"}:{" "}
            <strong>
              {
                attestationData[8384]?.identificationMarkEstablishments
                  ?.approvalNumber
              }
            </strong>
          </li>
          <li>
            Species{certificateNumber.length > 1 && "(Meat 8384)"}:{" "}
            <strong>
              {attestationData[8384]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>
        </>
      )}
      <li>
        EHC Number:{" "}
        <strong>{certificateNumber?.map((x) => x).join(", ")}</strong>
      </li>
      <li>
        EHC Name:
        <strong>
          {certificateNumber?.includes("8350") && <> Composite</>}
          {certificateNumber?.includes("8384") && <> Meat products</>}
          {certificateNumber?.includes("8383") && (
            <> Meat Preparation products</>
          )}
          {certificateNumber?.includes("8354") && <> Dairy products</>}
          {certificateNumber?.includes("8360") && <> Egg products</>}
          {certificateNumber?.includes("8361") && <> Fishery products</>}
          {certificateNumber?.includes("8399") && <> Highly Refined products</>}
          {certificateNumber?.includes("8390") && <> Gelatine products</>}
          {certificateNumber?.includes("8367") && <> Wild Game products</>}
          {certificateNumber?.includes("8370") && <> Porcine products</>}
          {certificateNumber?.includes("8369") && <> Ovine products</>}
          {certificateNumber?.includes("8368") && <> Bovine products</>}
          {certificateNumber?.includes("8371") && <> Poultry products</>}
          {certificateNumber?.includes("8393") && <> Casing products</>}
          {certificateNumber?.includes("8325") && <> DogChews products</>}
          {certificateNumber?.includes("8396") && <> Collagen products</>}
          {certificateNumber?.includes("8391") && <> Honey products</>}
          {certificateNumber?.includes("8468") && <> Dairy products</>}
          {certificateNumber?.includes("8392") && (
            <> Products not covered by article 8 to 26</>
          )}
        </strong>
      </li>
      {/* {!isGEFS && */}
      <>
        <li>
          Net weight {productDetail?.weightType == 1 ? "(units)" : "(cases)"}:{" "}
          <strong>{productDetail?.netWeight?.toFixed(3)}kg</strong>
        </li>
        <li>
          Gross weight {productDetail?.weightType == 1 ? "(units)" : "(cases)"}:{" "}
          <strong>{productDetail?.grossWeight?.toFixed(3)}kg</strong>
        </li>
      </>
      {/* } */}
      <li>
        Commodity Code (HS): <strong>{productDetail.commodityHscode}</strong>
      </li>
      <li>
        Site Name: <strong> {site.siteName}</strong>
      </li>
      <li>
        Site {site.establishmentNumberTypeName}:{" "}
        <strong> {site.establishmentNumber}</strong>
      </li>
      <li>
        Temperature: <strong> {temperature}</strong>
      </li>
      {certificateNumber.includes("8361") && (
        <>
          <li>
            Treatment Type{certificateNumber.length > 1 && " (Fishery 8361)"}:{" "}
            <strong>{attestationData[8361]?.treatmentType}</strong>
          </li>
          <li>
            Nature Of Commodity
            {certificateNumber.length > 1 && " (Fishery 8361)"}:{" "}
            <strong>
              {attestationData[8361]?.fisheryAquaculture == true && (
                <strong>Aquaculture </strong>
              )}
              {attestationData[8361]?.fisheryWildOrigin == true && (
                <strong>Wild Origin</strong>
              )}
            </strong>
          </li>
          <li>
            Certified As Or For
            {certificateNumber.length > 1 && " (Fishery 8361)"}:{" "}
            <strong>{attestationData[8361]?.certifiedAsOrFor}</strong>
          </li>
          <li>
            Species{certificateNumber.length > 1 && " (Fishery 8361)"}:{" "}
            <strong>
              {attestationData[8361]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>
          <li>
            Coldstore{certificateNumber.length > 1 && " (Fishery 8361)"}:{" "}
            <strong>
              {attestationData[8361]?.coldStoreEstablishments
                ?.map((x) => x.approvalNumber)
                .join(", ")}
            </strong>
          </li>
          <li>
            Identification Mark
            {certificateNumber.length > 1 && " (Fishery 8361)"}:{" "}
            <strong>
              {
                attestationData[8361]?.identificationMarkEstablishment
                  ?.approvalNumber
              }
            </strong>
          </li>
        </>
      )}

      {certificateNumber.includes("8383") && (
        <>
          <li>
            Coldstore Approval Number
            {certificateNumber.length > 1 && " (Meat Preparation 8383)"}:{" "}
            <strong>
              {attestationData[8383]?.coldStoreEstablishments
                ?.map((x) => x.approvalNumber)
                .join(", ")}
            </strong>
          </li>
          <li>
            Manufacturing Plant Approval Number
            {certificateNumber.length > 1 && " (Meat Preparation 8383)"}:{" "}
            <strong>
              {attestationData[8383]?.manufacturingPlantEstablishments
                ?.map((x) => x.approvalNumber)
                .join(", ")}
            </strong>
          </li>
          <li>
            Abattoir Approval number
            {certificateNumber.length > 1 && " (Meat Preparation 8383)"}:{" "}
            <strong>
              {attestationData[8383]?.abattoirEstablishments
                ?.map((x) => x.approvalNumber)
                .join(", ")}
            </strong>
          </li>
          <li>
            Identification Mark
            {certificateNumber.length > 1 && " (Meat Preparation 8383)"}:{" "}
            <strong>
              {
                attestationData[8383]?.identificationMarkEstablishment
                  ?.approvalNumber
              }
            </strong>
          </li>
          <li>
            Species{certificateNumber.length > 1 && " (Meat Preparation 8383)"}:{" "}
            <strong>
              {attestationData[8383]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>
        </>
      )}

      {certificateNumber.includes("8391") && (
        <>
          <li>
            Species{certificateNumber.length > 1 && " (Honey 8391)"}:{" "}
            <strong>{attestationData[8391]?.species}</strong>
          </li>
          <li>
            Country of Origin{certificateNumber.length > 1 && " (Honey 8391)"}:{" "}
            <strong>
              {attestationData[8391]?.honeyCountryOrigin
                ?.map((x) => x.countryName)
                .join(", ")}
            </strong>
          </li>
          <li>
            Treatment Type{certificateNumber.length > 1 && " (Honey 8391)"}:{" "}
            <strong>{attestationData[8391]?.treatmentType}</strong>
          </li>
          {attestationData[8391]?.honeyEstablishments?.length > 0 && (
            <li>
              Honey Establishment(s)
              {certificateNumber.length > 1 && " (Honey 8391)"}:{" "}
              <strong>
                {attestationData[8391]?.honeyEstablishments
                  ?.map((x) => x.approvalNumber)
                  .join(", ")}
              </strong>
            </li>
          )}
          {attestationData[8391]?.coldStoreEstablishments?.length > 0 && (
            <li>
              Cold Store{certificateNumber.length > 1 && " (Honey 8391)"}:{" "}
              <strong>
                {attestationData[8391]?.coldStoreEstablishments
                  ?.map((x) => x.approvalNumber)
                  .join(", ")}
              </strong>
            </li>
          )}
        </>
      )}
      {certificateNumber.includes("8399") && (
        <>
          <li>
            Species{certificateNumber.length > 1 && " (Highly Refined 8399)"}:{" "}
            <strong>
              {attestationData[8399]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>
          <li>
            Manufacturing Plant
            {certificateNumber.length > 1 && " (Highly Refined 8399)"}:{" "}
            <strong>
              {attestationData[8399]?.manufacturingPlantEstablishments
                ?.map((x) => x.approvalNumber)
                .join(", ")}
            </strong>
          </li>
          <li>
            Identification Mark
            {certificateNumber.length > 1 && " (Highly Refined 8399)"}:{" "}
            <strong>
              {
                attestationData[8399]?.identificationMarkEstablishment
                  ?.approvalNumber
              }
            </strong>
          </li>
        </>
      )}
      {certificateNumber.includes("8396") && (
        <>
          <li>
            Species{certificateNumber.length > 1 && " (Collagen 8396)"}:{" "}
            <strong>
              {attestationData[8396]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>
          <li>
            Manufacturing Plant
            {certificateNumber.length > 1 && " (Collagen 8396)"}:{" "}
            <strong>
              {attestationData[8396]?.manufacturingPlantEstablishments
                ?.map((x) => x.approvalNumber)
                .join(", ")}
            </strong>
          </li>
          <li>
            Identification Mark
            {certificateNumber.length > 1 && " (Collagen 8396)"}:{" "}
            <strong>
              {
                attestationData[8396]?.identificationMarkEstablishment
                  ?.approvalNumber
              }
            </strong>
          </li>
        </>
      )}
      {certificateNumber.includes("8390") && (
        <>
          <li>
            Species{certificateNumber.length > 1 && " (Gelatine 8390)"}:{" "}
            <strong>
              {attestationData[8390]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>
          <li>
            Manufacturing Plant
            {certificateNumber.length > 1 && " (Gelatine 8390)"}:{" "}
            <strong>
              {attestationData[8390]?.manufacturingPlantEstablishments
                ?.map((x) => x.approvalNumber)
                .join(", ")}
            </strong>
          </li>
          <li>
            Identification Mark
            {certificateNumber.length > 1 && " (Gelatine 8390)"}:{" "}
            <strong>
              {
                attestationData[8390]?.identificationMarkEstablishment
                  ?.approvalNumber
              }
            </strong>
          </li>
        </>
      )}
      {certificateNumber.includes("8367") && (
        <>
          <li>
            Game handling EU Approval number{" "}
            {certificateNumber.length > 1 && "(Wild Game 8367)"}:{" "}
            <strong>{attestationData[8367]?.euApprovalNumber}</strong>
          </li>
          <li>
            Nature of Commodity{" "}
            {certificateNumber.length > 1 && "(Wild Game 8367)"}:
            <strong>
              {attestationData[8367]?.wildGameCarcaseWhole
                ? " Carcase Whole. "
                : ""}
            </strong>
            <strong>
              {attestationData[8367]?.wildGameCarcaseSide
                ? " Carcase Side. "
                : ""}
            </strong>
            <strong>
              {attestationData[8367]?.wildGameCarcaseQuarters
                ? " Carcase Quarters. "
                : ""}
            </strong>
            <strong>
              {attestationData[8367]?.wildGameCuts ? " Cuts. " : ""}
            </strong>
            <strong>
              {attestationData[8367]?.wildGameOffal ? " Offal. " : ""}
            </strong>
          </li>
          <li>
            Species {certificateNumber.length > 1 && "(Wild Game 8367)"}:{" "}
            <strong>{attestationData[8367]?.species}</strong>
          </li>
          <li>
            Treatment Type {certificateNumber.length > 1 && "(Wild Game 8367)"}:{" "}
            <strong>{attestationData[8367]?.treatmentType}</strong>
          </li>
          {attestationData[8367]?.storageDate != "" && (
            <li>
              Freeze Date(s){" "}
              {certificateNumber.length > 1 && "(Wild Game 8367)"}:{" "}
              <strong>
                <span>
                  {attestationData[8367]?.storageDate.length
                    ? attestationData[8367]?.storageDate
                        .split(",")
                        .map((itemTestArray) => (
                          <span>
                            {" "}
                            {moment(itemTestArray).format("DD/MM/YYYY")}.{" "}
                          </span>
                        ))
                    : "-"}
                </span>
              </strong>
            </li>
          )}
        </>
      )}
      {certificateNumber.includes("8393") && (
        <>
          <li>
            Species{certificateNumber.length > 1 && " (Casing 8393)"}:{" "}
            <strong>
              {attestationData[8393]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>
          <li>
            Manufacturing Plant
            {certificateNumber.length > 1 && " (Casing 8393)"}:{" "}
            <strong>
              {attestationData[8393]?.manufacturingPlantEstablishments
                ?.map((x) => x.approvalNumber)
                .join(", ")}
            </strong>
          </li>
          <li>
            Identification Mark
            {certificateNumber.length > 1 && " (Casing 8393)"}:{" "}
            <strong>
              {
                attestationData[8393]?.identificationMarkEstablishment
                  ?.approvalNumber
              }
            </strong>
          </li>
        </>
      )}
      {certificateNumber.includes("8325") && (
        <>
          <li>
            Species{certificateNumber.length > 1 && " (DogChews 8325)"}:{" "}
            <strong>
              {attestationData[8325]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>
          <li>
            Manufacturing Plant
            {certificateNumber.length > 1 && " (DogChews 8325)"}:{" "}
            <strong>{attestationData[8325]?.manufacturingPlant}</strong>
          </li>
          <li>
            Commodities certified for
            {certificateNumber.length > 1 && " (DogChews 8325)"}:{" "}
            <strong>{attestationData[8325]?.commoditiesCertifiedFor}</strong>
          </li>
        </>
      )}
      {certificateNumber.includes("8370") && (
        <>
          <li>
            Treatment Type {certificateNumber.length > 1 && "(Porcine 8370)"}:{" "}
            <strong>{attestationData[8370]?.treatmentType}</strong>
          </li>
          <li>
            EU approval numbers of Slaugherhouses{" "}
            {certificateNumber.length > 1 && "(Porcine 8370)"}:{" "}
            <strong>{attestationData[8370]?.slaugherhouses}</strong>
          </li>
          <li>
            EU approval number of Cutting plants{" "}
            {certificateNumber.length > 1 && "(Porcine 8370)"}:{" "}
            <strong>{attestationData[8370]?.cuttingPlant}</strong>
          </li>
          <li>
            Identification marks{" "}
            {certificateNumber.length > 1 && "(Porcine 8370)"}:{" "}
            <strong>{attestationData[8370]?.IdentificationMarks}</strong>
          </li>
          <li>
            Date of production{" "}
            {certificateNumber.length > 1 && "(Porcine 8370)"}:{" "}
            <strong>{attestationData[8370]?.dateOfProduction}</strong>
          </li>
          <li>
            Coldstore {certificateNumber.length > 1 && "(Porcine 8370)"}:{" "}
            <strong>{attestationData[8370]?.coldstore}</strong>
          </li>
          <li>
            Species {certificateNumber.length > 1 && "(Porcine 8370)"}:{" "}
            <strong>
              {attestationData[8370]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>
          <li>
            Nature of Commodity{" "}
            {certificateNumber.length > 1 && "(Porcine 8370)"}:
            <strong>
              {attestationData[8370]?.porcineCarcaseWhole
                ? " Carcase Whole. "
                : ""}
            </strong>
            <strong>
              {attestationData[8370]?.porcineCarcaseSide
                ? " Carcase Side. "
                : ""}
            </strong>
            <strong>
              {attestationData[8370]?.porcineCarcaseQuarters
                ? " Carcase Quarters. "
                : ""}
            </strong>
            <strong>
              {attestationData[8370]?.porcineCuts ? " Cuts. " : ""}
            </strong>
            <strong>
              {attestationData[8370]?.porcineOffal ? " Offal. " : ""}
            </strong>
            {attestationData[8370]?.storageDate != "" && (
              <li>
                Freeze Date(s){" "}
                {certificateNumber.length > 1 && "(Porcine 8370)"}:{" "}
                <strong>
                  <span>
                    {attestationData[8370]?.storageDate.length
                      ? attestationData[8370]?.storageDate
                          .split(",")
                          .map((itemTestArray) => (
                            <span>
                              {" "}
                              {moment(itemTestArray).format("DD/MM/YYYY")}.{" "}
                            </span>
                          ))
                      : "-"}
                  </span>
                </strong>
              </li>
            )}
          </li>
        </>
      )}
      {certificateNumber.includes("8369") && (
        <>
          <li>
            Treatment Type {certificateNumber.length > 1 && "(Ovine 8369)"} :{" "}
            <strong>{attestationData[8369]?.treatmentType}</strong>
          </li>
          <li>
            EU approval numbers of Slaugherhouses{" "}
            {certificateNumber.length > 1 && "(Ovine 8369)"} :{" "}
            <strong>{attestationData[8369]?.slaugherhouses}</strong>
          </li>
          <li>
            EU approval number of Cutting plants{" "}
            {certificateNumber.length > 1 && "(Ovine 8369)"} :{" "}
            <strong>{attestationData[8369]?.cuttingPlant}</strong>
          </li>
          <li>
            Identification marks{" "}
            {certificateNumber.length > 1 && "(Ovine 8369)"} :{" "}
            <strong>{attestationData[8369]?.IdentificationMarks}</strong>
          </li>
          <li>
            Date of production {certificateNumber.length > 1 && "(Ovine 8369)"}{" "}
            : <strong>{attestationData[8369]?.dateOfProduction}</strong>
          </li>
          <li>
            Coldstore {certificateNumber.length > 1 && "(Ovine 8369)"} :{" "}
            <strong>{attestationData[8369]?.coldstore}</strong>
          </li>
          <li>
            Species {certificateNumber.length > 1 && "(Ovine 8369)"} :{" "}
            <strong>
              {attestationData[8369]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>
          <li>
            Nature of Commodity {certificateNumber.length > 1 && "(Ovine 8369)"}{" "}
            :
            <strong>
              {attestationData[8369]?.OvineCarcaseWhole
                ? " Carcase Whole. "
                : ""}
            </strong>
            <strong>
              {attestationData[8369]?.OvineCarcaseSide ? " Carcase Side. " : ""}
            </strong>
            <strong>
              {attestationData[8369]?.OvineCarcaseQuarters
                ? " Carcase Quarters. "
                : ""}
            </strong>
            <strong>{attestationData[8369]?.OvineCuts ? " Cuts. " : ""}</strong>
            <strong>
              {attestationData[8369]?.OvineOffal ? " Offal. " : ""}
            </strong>
            {attestationData[8369]?.storageDate != "" && (
              <li>
                Freeze Date(s) {certificateNumber.length > 1 && "(Ovine 8369)"}{" "}
                :{" "}
                <strong>
                  <span>
                    {attestationData[8369]?.storageDate.length
                      ? attestationData[8369]?.storageDate
                          .split(",")
                          .map((itemTestArray) => (
                            <span>
                              {" "}
                              {moment(itemTestArray).format("DD/MM/YYYY")}.{" "}
                            </span>
                          ))
                      : "-"}
                  </span>
                </strong>
              </li>
            )}
          </li>
        </>
      )}
      {certificateNumber.includes("8392") && (
        <>
          <li>
            Species
            {certificateNumber.length > 1 &&
              " (Products not covered by article 8 to 26 8392)"}
            :{" "}
            <strong>
              {attestationData[8392]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>
        </>
      )}
      {certificateNumber.includes("8368") && (
        <>
          <li>
            Treatment Type {certificateNumber.length > 1 && "(Bovine 8368)"} :{" "}
            <strong>{attestationData[8368]?.treatmentType}</strong>
          </li>
          <li>
            EU approval numbers of Slaugherhouses{" "}
            {certificateNumber.length > 1 && "(Bovine 8368)"} :{" "}
            <strong>{attestationData[8368]?.slaugherhouses}</strong>
          </li>
          <li>
            EU approval number of Cutting plants{" "}
            {certificateNumber.length > 1 && "(Bovine 8368)"} :{" "}
            <strong>{attestationData[8368]?.cuttingPlant}</strong>
          </li>
          <li>
            Identification marks{" "}
            {certificateNumber.length > 1 && "(Bovine 8368)"} :{" "}
            <strong>{attestationData[8368]?.IdentificationMarks}</strong>
          </li>
          <li>
            Date of production {certificateNumber.length > 1 && "(Bovine 8368)"}{" "}
            : <strong>{attestationData[8368]?.dateOfProduction}</strong>
          </li>
          <li>
            Coldstore {certificateNumber.length > 1 && "(Bovine 8368)"} :{" "}
            <strong>{attestationData[8368]?.coldstore}</strong>
          </li>
          <li>
            Species {certificateNumber.length > 1 && "(Bovine 8368)"} :{" "}
            <strong>
              {attestationData[8368]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>
          <li>
            Nature of Commodity{" "}
            {certificateNumber.length > 1 && "(Bovine 8368)"} :
            <strong>
              {attestationData[8368]?.BovineCarcaseWhole
                ? " Carcase Whole. "
                : ""}
            </strong>
            <strong>
              {attestationData[8368]?.BovineCarcaseSide
                ? " Carcase Side. "
                : ""}
            </strong>
            <strong>
              {attestationData[8368]?.BovineCarcaseQuarters
                ? " Carcase Quarters. "
                : ""}
            </strong>
            <strong>
              {attestationData[8368]?.BovineCuts ? " Cuts. " : ""}
            </strong>
            <strong>
              {attestationData[8368]?.BovineOffal ? " Offal. " : ""}
            </strong>
            {attestationData[8368]?.storageDate != "" && (
              <li>
                Freeze Date(s) {certificateNumber.length > 1 && "(Bovine 8368)"}{" "}
                :{" "}
                <strong>
                  <span>
                    {attestationData[8368]?.storageDate.length
                      ? attestationData[8368]?.storageDate
                          .split(",")
                          .map((itemTestArray) => (
                            <span>
                              {" "}
                              {moment(itemTestArray).format("DD/MM/YYYY")}.{" "}
                            </span>
                          ))
                      : "-"}
                  </span>
                </strong>
              </li>
            )}
          </li>
        </>
      )}
      {certificateNumber.includes("8371") && (
        <>
          <li>
            Abattoirs{certificateNumber.length > 1 && "( Poultry 8371)"}:{" "}
            <strong>
              {attestationData[8371]?.abattoirEstablishments
                ?.map((x) => x.approvalNumber)
                .join(", ")}
            </strong>
          </li>
          {attestationData[8371]?.coldStoreEstablishments?.length > 0 && (
            <li>
              Coldstore{certificateNumber.length > 1 && "( Poultry 8371)"}:{" "}
              <strong>
                {attestationData[8371]?.coldStoreEstablishments
                  ?.map((x) => x.approvalNumber)
                  .join(", ")}
              </strong>
            </li>
          )}
          {attestationData[8371]?.cuttingPlantEstablishments?.length > 0 && (
            <li>
              Cutting plants{certificateNumber.length > 1 && "( Poultry 8371)"}:{" "}
              <strong>
                {attestationData[8371]?.cuttingPlantEstablishments
                  ?.map((x) => x.approvalNumber)
                  .join(", ")}
              </strong>
            </li>
          )}
          <li>
            Species{certificateNumber.length > 1 && "( Poultry 8371)"}:{" "}
            <strong>
              {attestationData[8371]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>
        </>
      )}
      {certificateNumber.includes("8360") && (
        <>
          <li>
            Identification mark{certificateNumber.length > 1 && "( Egg 8360)"}:{" "}
            <strong>
              {
                attestationData[8360]?.identificationMarkEstablishment
                  ?.approvalNumber
              }
            </strong>
          </li>
          <li>
            Date of production{certificateNumber.length > 1 && "( Egg 8360)"}:{" "}
            <strong>{attestationData[8360]?.dateOfProduction}</strong>
          </li>
          <li>
            Coldstore{certificateNumber.length > 1 && "( Egg 8360)"}:{" "}
            <strong>
              {attestationData[8360]?.coldStoreEstablishments
                ?.map((x) => x.approvalNumber)
                .join(", ")}
            </strong>
          </li>
          <li>
            Species{certificateNumber.length > 1 && "( Egg 8360)"}:{" "}
            <strong>
              {attestationData[8360]?.productScientificSpecies
                ?.map((x) => x.speciesName)
                .join(", ")}
            </strong>
          </li>{" "}
        </>
      )}
    </ul>
  );
};

export const Batches = (props) => {
  const {
    setFieldValue,
    values,
    batchNumberOptions,
    batchNumbers,
    batchFormat,
    batchRangeFrom,
    batchRangeTo,
    batchNumber,
    isReadOnly,
    isGEFS,
    onChange,
    onBlur,
    touched,
    error,
  } = props;

  return (
    <>
      {isReadOnly ? (
        <>
          {isGEFS ? (
            <>
              <p className="mt-3 mb-0">
                The batch range that would{" "}
                <strong>
                  <u>likely be covered</u>
                </strong>{" "}
                under this Support Attestation
              </p>
              <strong>
                Batch numbers: {batchRangeFrom} to {batchRangeTo}
              </strong>
            </>
          ) : (
            <>
              <p className="mt-3 mb-0">
                The batch that will be covered under this Support Attestation
              </p>
              <strong>Batch number: {batchNumber}</strong>
            </>
          )}
        </>
      ) : (
        <Row className="">
          <Col md={12}>
            <h5>Batches</h5>
            {isGEFS ? (
              <p className="mb-0">
                The batch range that would <strong>likely</strong> be covered
                under this Support Attestation
                <span className="is-required"></span>
              </p>
            ) : (
              <p className="mb-0">
                The batch that will be covered under this Support Attestation
              </p>
            )}
          </Col>

          {isGEFS ? (
            <>
              <Col md={12}>
                <div className="mb-3">
                  <AttestationBatchRange
                    hasBatchRange={
                      values.batchRangeFrom !== "" && values.batchRangeTo !== ""
                    }
                    setFieldValue={setFieldValue}
                    batchFormat={batchFormat}
                    batchRangeFrom={batchRangeFrom}
                    batchRangeTo={batchRangeTo}
                    batchNumbers={batchNumbers}
                    batchNumberOptions={batchNumberOptions}
                  />
                </div>

                <strong className="mt-3">
                  Batch range selected:<span className="is-required"></span>{" "}
                </strong>
                {values.batchRangeFrom !== "" && values.batchRangeTo !== "" ? (
                  <span>
                    {values.batchRangeFrom} to {values.batchRangeTo}
                  </span>
                ) : (
                  <span>Not yet selected.</span>
                )}

                {values.batchNumbers?.length > 0 && (
                  <MultiSelectField
                    label={null}
                    placeholder="Select batches"
                    options={values.batchNumberOptions}
                    getOptionValue={(option) => option}
                    getOptionLabel={(option) => option}
                    touched={touched}
                    error={error}
                    isMulti={true}
                    onChange={onChange}
                    onBlur={onBlur}
                    isClearable={true}
                    col={12}
                    id={batchNumbers}
                    name={[batchNumbers.name]}
                    isrequired="true"
                    backspaceRemovesValue={true}
                    readOnly={false}
                    className="mt-2"
                  />
                )}
              </Col>
            </>
          ) : (
            <>
              <Col md={3}>
                <FormController
                  control="input"
                  label={batchNumber.label}
                  isrequired={"true"}
                  name={batchNumber.name}
                />
              </Col>
            </>
          )}
        </Row>
      )}
    </>
  );
};

export const DateCollectionProductionText = (props) => {
  const { dateCollectionProduction, isReadOnly, isGEFS } = props;
  return (
    <>
      {!isGEFS && (
        <>
          <p className="mt-3 mb-0">
            Date(s) of Collection / Production:
            <strong>{dateCollectionProduction}</strong>
          </p>
        </>
      )}
    </>
  );
};
export const AuthorisedBy = (props) => {
  const { user, manufacturer } = props;
  return (
    <>
      <h5 className="authorised-by">Authorised by</h5>
      <ul className="list-unstyled">
        <li>
          <h1 className="d-none d-print-block signature-placeholder">
            [sig|req|signer1]
          </h1>
        </li>
        <li>
          <strong>Name: {user.fullName}</strong>
        </li>
        <li>
          <strong>Email address: {user.email}</strong>
        </li>
        <li>
          <strong>Company Name: {manufacturer.manufacturerName}</strong>
        </li>
        <li>
          <strong>Date: {moment(props.value).format("Do MMMM YYYY")}</strong>
        </li>
      </ul>
      <p className="mt-3">
        <small>
          This signed document meets the requirements of the European Union
          eIDAS (EU No.910/2014), the Uniform Electronic Transactions Act (UETA)
          and the U.S. Electronic Signature in Global and National Commerce Act
          of 2000 (ESIGN) regarding electronic signatures and transmissions.
        </small>
      </p>
    </>
  );
};

export const SignedBy = (props) => {
  const { user, manufacturerName } = props;
  return (
    <>
      <h5 className="authorised-by">&nbsp;</h5>
      <ul className="list-unstyled">
        <li>
          <h1 className="d-none d-print-block signature-placeholder">
            [sig|req|signer1]
          </h1>
        </li>
        <li>
          <strong>Name: {user.fullName}</strong>
        </li>
        <li>
          <strong>Email address: {user.email}</strong>
        </li>
        <li>
          <strong>Company Name: {manufacturerName}</strong>
        </li>
        <li>
          <strong>Date: {moment(props.value).format("Do MMMM YYYY")}</strong>
        </li>
      </ul>
      <p className="mt-3">
        <small>
          This signed document meets the requirements of the European Union
          eIDAS (EU No.910/2014), the Uniform Electronic Transactions Act (UETA)
          and the U.S. Electronic Signature in Global and National Commerce Act
          of 2000 (ESIGN) regarding electronic signatures and transmissions.
        </small>
      </p>
    </>
  );
};
export const VetAuthorisedBy = (props) => {
  const { user } = props;
  return (
    <>
      <h5 className="authorised-by">Registered Vet</h5>
      <ul className="list-unstyled">
        <li>
          <h1 className="d-none d-print-block signature-placeholder">
            [sig|req|signer1]
          </h1>
        </li>
        <li>
          <strong>Signed name: </strong> {user.fullName}
        </li>
        <li>
          <strong>RCVS number: </strong> {user.rcvs}
        </li>
        <li>
          <strong>Practice name: </strong>
          {user.practiceName}
        </li>
        <li>
          <strong>Address: </strong>
          {user.address}
        </li>
        <li>
          <strong>Email address: </strong>
          {user.email}
        </li>
      </ul>
      <p className="mt-3">
        <small>
          This signed document meets the requirements of the European Union
          eIDAS (EU No.910/2014), the Uniform Electronic Transactions Act (UETA)
          and the U.S. Electronic Signature in Global and National Commerce Act
          of 2000 (ESIGN) regarding electronic signatures and transmissions.
        </small>
      </p>
    </>
  );
};

export const AttestationNonGEFSQuestions = (props) => {
  const {
    approvedExportCountries,
    approvedExportCountriesOfOrigin,
    numberProductsCases,
    isReadOnly = false,
    certificateNumber,
  } = props;
  const { values: formValues, errors: formErrors } = useFormikContext();
  let approvedExportCountriesOfOriginOptions = [
    {
      group: "approvedExportCountriesOfOriginOptions",
      fieldname: "approvedExportCountriesOfOriginOption1",
      value: 1,
      key: "European Union",
    },
    {
      group: "approvedExportCountriesOfOriginOptions",
      fieldname: "approvedExportCountriesOfOriginOption2",
      value: 2,
      key: "Other Countries",
    },
  ];
  if (certificateNumber?.includes("8371")) {
    approvedExportCountriesOfOriginOptions =
      approvedExportCountriesOfOriginOptions.filter((item) => item.value !== 2);
    formValues.approvedExportCountries = 1;
    formValues.isDateRequired = 0;
    formValues.isDateCollectionProductionMulti = 0;
  }
  return !isReadOnly ? (
    <>
      <FormController
        control="radio"
        options={approvedExportCountriesOfOriginOptions}
        isrequired="true"
        col={12}
        label={approvedExportCountries.label}
        name={approvedExportCountries.name}
      />
      {formValues.approvedExportCountries === "2" && (
        <FormController
          control="input"
          isrequired="true"
          className="mb-3"
          col={6}
          label={approvedExportCountriesOfOrigin.label}
          name={approvedExportCountriesOfOrigin.name}
        />
      )}

      <FormController
        control="input"
        isrequired="true"
        type="number"
        className="mb-3"
        col={3}
        label={numberProductsCases.label}
        name={numberProductsCases.name}
      />

      {/* {
                    certificateNumber.includes("8371") && (
                        <FieldArray
                            name="hatcheries"
                            render={({ insert, remove, push }) => (
                                <div>
                                    {formValues.hatcheries?.length > 0 &&
                                        formValues.hatcheries?.map((x, index) => (
                                            <Row key={1 + index}>
                                                <Col md={3} lg={2}>
                                                    <FormController
                                                        control="input"
                                                        label={`Farm: ${1 + index}`}
                                                        isrequired="true"
                                                        name={`hatcheries[${index}].hatcheryName`}
                                                        value={formValues.hatcheries?.[index].hatcheryName}
                                                    />
                                                </Col>
                                                <Col md={3} lg={2}>
                                                    <FormController
                                                        control="input"
                                                        label={`Farm Postcode: ${1 + index}`}
                                                        isrequired="true"
                                                        name={`hatcheries[${index}].hatcheryPostcode`}
                                                        value={formValues.hatcheries?.[index].hatcheryPostcode}
                                                    />
                                                </Col>
                                                <Col md={3} lg={2} className="mt-5">
                                                    {(index > 0 &&
                                                        <Button variant="outline-danger" onClick={() => remove(index)}>
                                                            <FontAwesomeIcon icon={faTimes} className="mx-2" />
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        ))}
                                    <Button variant="light" className="mt-3 mb-2" onClick={() => push({ hatcheryName: "" })}>
                                        Add Farm
                                    </Button>
                                </div>
                            )}
                        />
                    )
                } */}
      <br />
      {/* <PrettyPrintJson data={formValues}/> */}
    </>
  ) : (
    <>
      <p className="mt-3">
        I certify the establishment listed in Section B is/are approved for
        export to&nbsp;
        <strong>
          {formValues.approvedExportCountries === "1"
            ? "the European Union"
            : formValues.approvedExportCountriesofOrigin}
        </strong>
      </p>
      <p>
        Number of Products/Cases &nbsp;
        <strong>{formValues.numberProductsCases}</strong>
      </p>
      {/* {
                        formValues.hatcheries?.length > 0 && (
                            <p className="mt-3">Listed below are the Farm names and postcodes for kill date above&nbsp;
                            </p>
                        )
                    }
                    {
                        formValues.hatcheries?.length > 0 && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Farm Name</th>
                                        <th>Postcode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        formValues.hatcheries.map((hatchery, index) => (
                                            <tr key={index}>
                                                <strong><td>{hatchery.hatcheryName}</td></strong>
                                                <strong><td>{hatchery.hatcheryPostcode}</td></strong>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        )
                    } */}
    </>
  );
};

export const AttestationResponseVetInspection = (props) => {
  const {
    dateInspection,
    inspectionType,
    inspectionTypeChoice,
    isReadOnly,
    site,
  } = props;
  const { values: formValues, errors: formErrors } = useFormikContext();
  return (
    <>
      {!isReadOnly ? (
        <>
          <FormController
            control="radio"
            label={inspectionType.label}
            name={inspectionType.name}
            options={inspectionTypeChoice}
          />

          <FormController
            control="dateInput"
            isrequired="true"
            col={3}
            label={dateInspection.label}
            name={dateInspection.name}
          />
        </>
      ) : (
        <>
          {formValues.inspectionType == 1 ? (
            <p>This was a physical inspection.</p>
          ) : (
            <p>This was a remote inspection.</p>
          )}
          <p>
            <label>Date of Inspection</label>
            <br />
            {moment(formValues.dateInspection).format("D MMMM, YYYY")}
          </p>
        </>
      )}

      <label className="mt-3">Current Date of Expiry</label>
      <br />
      {formValues.dateInspection === ""
        ? moment().add(30, "days").format("D MMMM, YYYY")
        : moment(formValues.dateInspection)
            .add(30, "days")
            .format("D MMMM, YYYY")}
      <p className="mt-3">
        This Support Attestation is valid only for 30 days from the above date
        or until I am notified of any changes of the above supplier’s
        declaration (whichever is the sooner). If I am notified of changes that
        affect the date of expiry above I will notify the Official Veterinarian
        responsible for certifying exports from establishment(s) {site.siteName}
        , {site.fullAddress}.
      </p>
    </>
  );
};

export const VetDeclaration = (props) => {
  const {
    firstInspection,
    uniqueRef,
    changeStatement,
    isReadOnly,
    changesDescription,
    site,
  } = props;
  const { values: formValues, errors: formErrors } = useFormikContext();
  const changesChoice = [
    {
      group: "decision",
      fieldname: "decision1",
      value: 1,
      key: "There have been no changes to the product health and traceability details relevant to Section B of supplier declaration in the preceding 6 months",
    },
    {
      group: "decision",
      fieldname: "decision2",
      value: 2,
      key: "There have been no changes to the product health and traceability details relevant to Section B of supplier declaration in at least 4 of the preceding 6 months 14 and details, including the date of change(s), are described here:",
    },
  ];

  return (
    <div>
      <h4>A. Registered vet declaration</h4>
      <p className="mb-0">
        I, the undersigned registered veterinarian, hereby declare that I have
        inspected the supplying premise{" "}
        <strong>
          {site.siteName}, {site.fullAddress}
        </strong>{" "}
        and, having reviewed the relevant supplier’s manufacturing and
        traceability processes including relevant documentary evidence
        concerning all products in Section A, Part 2 of this supplier
        declaration, I can confirm that the supplier attestations provided in
        Section A and B with a<h5>Unique Reference: {uniqueRef}</h5> are
        currently correct.
      </p>
      <p>
        I confirm that I have seen written confirmation from the managing
        director (or equivalent) of the supplying company to verify that the
        signatory of supplier declaration is authorised to sign this document on
        behalf of the supplying company.
      </p>
      <p>
        There have been no additions, removal or alterations to the product
        health and traceability details relevant to the supplier declaration in
        Section B in the proceeding 30 days.
      </p>

      {!isReadOnly ? (
        <>
          <FormController
            control="checkbox"
            isrequired={"true"}
            className="my-3"
            label={firstInspection.label}
            name={firstInspection.name}
          />
          {formValues.firstInspection == 1 && (
            <Row className="my-3">
              <Col md={12}>
                <FormController
                  control="radio"
                  label={null}
                  name={changeStatement.name}
                  options={changesChoice}
                />
              </Col>
              {formValues.changeStatement == 2 && (
                <Col md={12}>
                  <FormController
                    control="textarea"
                    isrequired="true"
                    fieldheight="100px"
                    col={12}
                    label={changesDescription.label}
                    name={changesDescription.name}
                  />
                </Col>
              )}
            </Row>
          )}
        </>
      ) : (
        <>
          {formValues.firstInspection == 1 ? (
            <>
              <p>This is the first inspection</p>
              <ul>
                <li>
                  {formValues.changeStatement == 1 && changesChoice[0].key}
                  {formValues.changeStatement == 2 && changesChoice[1].key}
                  {formValues.changeStatement == 2 && (
                    <p>{formValues.changesDescription}</p>
                  )}
                </li>
              </ul>
            </>
          ) : (
            <p>This isn't the first inspection</p>
          )}
        </>
      )}
    </div>
  );
};
