import React from "react";


// Default values from attestation

export function CuttingPlantEstablishmentValues(attestationData) {
  let cuttingPlantsHelper = null;
  if (attestationData !== null && attestationData !== undefined) {
    //Composite 8350 Set cutting plants helper text
    var composite8350CuttingPlants = attestationData[8350]?.cuttingPlantEstablishments;
    if (composite8350CuttingPlants !== undefined) {
      cuttingPlantsHelper = composite8350CuttingPlants;
    }
    //Meat 8384 Set cutting plants helper text
    var meat8384CuttingPlants = attestationData[8384]?.cuttingPlantEstablishments;
    if (meat8384CuttingPlants !== undefined) {
      cuttingPlantsHelper = meat8384CuttingPlants;
    }
    // //Meat Prep 8383 Set cutting plants helper text
    // var meat8383CuttingPlants = attestationData[8383]?.cuttingPlant;
    // if(meat8383CuttingPlants !== undefined){
    //   cuttingPlantsHelper = meat8383CuttingPlants;
    // }
  }

  if (cuttingPlantsHelper !== null && cuttingPlantsHelper !== "") {
    return cuttingPlantsHelper;
  }

  return null;
}

export function AbattoirEstablishmentValues(attestationData) {
  let abattoirHelper = null;

  if (attestationData !== null && attestationData !== undefined) {
    //Composite 8350 Set Abattoirs helper text
    var composite8350Abattoirs = attestationData[8350]?.meatData?.abattoirEstablishments;
    if (composite8350Abattoirs !== undefined) {
      abattoirHelper = composite8350Abattoirs;
    }
    //Meat 8384 Set Abattoirs helper text
    var meat8384Abattoirs = attestationData[8384]?.abattoirEstablishments;
    if (meat8384Abattoirs !== undefined) {
      abattoirHelper = meat8384Abattoirs;
    }
    //Meat Prep 8383 Set Abattoirs helper text
    var meat8383Abattoirs = attestationData[8383]?.abattoirEstablishments;
    if (meat8383Abattoirs !== undefined) {
      abattoirHelper = meat8383Abattoirs;
    }
  }

  if (abattoirHelper !== null && abattoirHelper !== "") {
    return abattoirHelper;
  }

  return null;
}

export function DairyEstablishmentValues(attestationData) {
  let dairyEstablishmentsHelper = null;
  if (attestationData !== null && attestationData !== undefined) {

    // Composite 8350 Set dairy establishment values
    var composite8350DairyApprovalCodes = attestationData[8350]?.dairyData?.dairyAapprovalNumberEstablishments ?? attestationData[8468]?.identificationMarkEstablishments;   
    if (composite8350DairyApprovalCodes !== undefined) {
      dairyEstablishmentsHelper = composite8350DairyApprovalCodes;  
    }
    // Alternative - Pre establishment change
    // var composite8350DairyApprovalCodesAlt = attestationData[8350]?.dairyData?.dairyAapprovalNumber?? attestationData[8468]?.identificationMark;
    // if (composite8350DairyApprovalCodesAlt !== undefined && composite8350DairyApprovalCodesAlt !== "") {
    //   dairyEstablishmentsHelper = composite8350DairyApprovalCodesAlt;
    // }
  }
  if (dairyEstablishmentsHelper !== null && dairyEstablishmentsHelper !== "" && dairyEstablishmentsHelper !== undefined) {
    if (Array.isArray(dairyEstablishmentsHelper)) {
      dairyEstablishmentsHelper = dairyEstablishmentsHelper;
    } else {
      dairyEstablishmentsHelper = [dairyEstablishmentsHelper];
    }
    return dairyEstablishmentsHelper;
  }

  return null;
}
export function MeatEstablishmentValues(attestationData) {

  let meatEstablishmentsHelper = null;
  if (attestationData !== null && attestationData !== undefined) {

    // Composite 8350 Set meat establishment values
    var composite8350speciesList = attestationData[8350]?.meatData?.speciesList;
    var composite8350MeatAp = [];
    if (composite8350speciesList?.length > 0) {
      composite8350MeatAp =  composite8350speciesList?.map(x => x.speciesData);
    }
    

    const establishmentCodeEstablishments = [];

    if (composite8350MeatAp && composite8350MeatAp.length > 0) {
      for (const subArray of composite8350MeatAp) {
        for (const item of subArray) {
          if (item.establishmentCodeEstablishments) {
            establishmentCodeEstablishments.push(...item.establishmentCodeEstablishments);
          }
        }
      }
    }
    if (establishmentCodeEstablishments !== undefined) {
      meatEstablishmentsHelper = establishmentCodeEstablishments;
    }
    // Alternative - Pre establishment change
    var composite8350MeatspeciesListAlt = attestationData[8350]?.meatData?.speciesList;

    var composite8350MeatApprovalCodesAlt = [];
    if (composite8350MeatspeciesListAlt?.length> 0) {
      composite8350MeatApprovalCodesAlt =  composite8350MeatspeciesListAlt?.map(x => x.speciesData);
    }
    let establishmentCodesArray = [];

    // Iterate through the nested arrays and objects to collect establishmentCodes
    for (const innerArray of composite8350MeatApprovalCodesAlt) {
      for (const obj of innerArray) {
        if (obj.establishmentCodes) {
          establishmentCodesArray.push(obj.establishmentCodes);
        }
      }
    }
    var establishmentCodesString = "";
    if (establishmentCodesArray.length > 0) {
      establishmentCodesString = establishmentCodesArray.join(', ');
    }
    if (establishmentCodesString !== "") {
      meatEstablishmentsHelper = establishmentCodesString;
    }
  }

  if (meatEstablishmentsHelper !== null && meatEstablishmentsHelper !== "") {
    return meatEstablishmentsHelper;
  }

  return null;
}


//Helper Text Checks
export function AbattoirHelperText(props) {
  const { attestationData } = props;
  let abattoirHelper = null;

  if (attestationData !== null && attestationData !== undefined) {
    //Composite 8350 Set Abattoirs helper text
    // var composite8350Abattoirs = attestationData[8350]?.meatData?.abattoir;
    // if (composite8350Abattoirs !== undefined) {
    //   abattoirHelper = composite8350Abattoirs;
    // }
    //Meat 8384 Set Abattoirs helper text
    var meat8384Abattoirs = attestationData[8384]?.abattoir;
    if (meat8384Abattoirs !== undefined) {
      abattoirHelper = meat8384Abattoirs;
    }
    //Meat Prep 8383 Set Abattoirs helper text
    var meat8383Abattoirs = attestationData[8383]?.abattoir;
    if (meat8383Abattoirs !== undefined) {
      abattoirHelper = meat8383Abattoirs;
    }
  }
  return (
    abattoirHelper !== null &&
    <small className="text-info mt-2">
      Abattoirs must be one of the following <strong>{abattoirHelper}</strong> as stated on the selected attestation.
    </small>
  );
}

//Helper Text Checks
export function CuttingPlantHelperText(props) {
  const { attestationData } = props;
  let cuttingPlantsHelper = null;
  if (attestationData !== null && attestationData !== undefined) {
    //Composite 8350 Set cutting plants helper text
    var composite8350CuttingPlants = attestationData[8350]?.cuttingPlant;
    if (composite8350CuttingPlants !== undefined) {
      cuttingPlantsHelper = composite8350CuttingPlants;
    }
    //Meat 8384 Set cutting plants helper text
    var meat8384CuttingPlants = attestationData[8384]?.cuttingPlant;
    if (meat8384CuttingPlants !== undefined) {
      cuttingPlantsHelper = meat8384CuttingPlants;
    }
    // //Meat Prep 8383 Set cutting plants helper text
    // var meat8383CuttingPlants = attestationData[8383]?.cuttingPlant;
    // if(meat8383CuttingPlants !== undefined){
    //   cuttingPlantsHelper = meat8383CuttingPlants;
    // }
  }
  return (
    cuttingPlantsHelper !== null && cuttingPlantsHelper !== "" &&
    <small className="text-info mt-2">
      Cutting plants must be one of the following <strong>{cuttingPlantsHelper}</strong> as stated on the selected attestation.
    </small>
  );
}
export function DairyHelperText(props) {
  const { attestationData } = props;

  if (attestationData !== null && attestationData !== undefined) {
    let dairyEstablishmentsHelper = null;

    //Composite 8350 Set Dairy helper text
    var composite8350DairyApprovalCodes = attestationData[8350]?.dairyData?.dairyAApprovalNumber ?? attestationData[8468]?.identificationMark;
    if (composite8350DairyApprovalCodes !== undefined) {
      dairyEstablishmentsHelper = composite8350DairyApprovalCodes;
    }
    return (
      dairyEstablishmentsHelper !== null &&
      <small className="text-info mt-2">
        Dairy Establishments must be one of the following <strong>{dairyEstablishmentsHelper}</strong> as stated on the selected attestation.
      </small>
    );
  }
  return null;
}
export function MeatHelperText(props) {
  const { attestationData } = props;

  if (attestationData !== null && attestationData !== undefined) {
    let MeatEstablishmentsHelper = null;
    var composite8350MeatspeciesListAlt = attestationData[8350]?.meatData?.speciesList;

    var composite8350MeatApprovalCodesAlt = composite8350MeatspeciesListAlt.map(x => x.speciesData);
    let establishmentCodesArray = [];

    if(composite8350MeatApprovalCodesAlt?.length > 0){
      // Iterate through the nested arrays and objects to collect establishmentCodes
      for (const innerArray of composite8350MeatApprovalCodesAlt) {
        for (const obj of innerArray) {
          if (obj.establishmentCodes) {
            establishmentCodesArray.push(obj.establishmentCodes);
          }
        }
      }
    }


    var establishmentCodesString = "";
    if (establishmentCodesArray.length > 0) {
      establishmentCodesString = establishmentCodesArray.join(', ');
    }


    if (establishmentCodesString !== undefined) {
      MeatEstablishmentsHelper = establishmentCodesString;
    }
    return (
      MeatEstablishmentsHelper !== null &&
      <small className="text-info mt-2">
        Meat Establishments must be one of the following <strong>{MeatEstablishmentsHelper}</strong> as stated on the selected attestation.
      </small>
    );
  }
  return null;
}


export function DateCollectionProductionHelperText(props) {
  const { dateCollectionProduction } = props;
  return (
    dateCollectionProduction !== null && dateCollectionProduction !== "" &&
    <small className="text-info mt-2">
      Date of production must be one of the following <strong>{JSON.stringify(dateCollectionProduction)}</strong> as stated on the selected attestation.
    </small>
  );
  return null;
}
