import React, { useState, useEffect } from "react";
import Preloader from "../../../../../../components/Preloader";
import { NoResults } from "../../../../../../components/Tables/NoResults";
import moment from "moment"
import { Button, Table } from '@themesberg/react-bootstrap';
import { useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ReadDataToProductItem } from './DailyAttestationProductListFunctions'
import { useDebugValue } from "react";

const DailyAttestationProductListTable = (props) => {
  const { attestationRequests: attestationRequests, loaded = false, selections } = props;
  const totalTransactions = attestationRequests?.length;
  const { values: formValues } = useFormikContext();

  const TableRow = (props) => {
    const { id, batchNumberRange, certificateNumberList, dailyAttestationItemId, validFrom, validTo, attestationDetails, attestationRequest, batchNumbers, selections } = props;

    const [selected, setSelected] = useState(false);

    const getSADate = (date) => {
      var newDate = moment(date).format("Do MMM YYYY");
      if (newDate === "Invalid date") {
        return "";
      }
      return newDate;
    }

    useEffect(() => {
      if (isSelected()) {
        setSelected(true)
      }
      else {
        setSelected(false)
      }
    }, []);

    const isSelected = () => {
      if (selections !== undefined) {
        const found = selections.find(x => x.id == id);
        if (found !== undefined && found !== null) {
          return true;
        }
      }
      return false;
    }

    const selectItem = (item) => {
      if (!isSelected()) {
        var duplicate = formValues.productsSelected?.find(x => x.dailyAttestationItemId == item.dailyAttestationItemId && x.id == item.id);
        if (!duplicate?.length) {
          formValues.productsSelected.push(item);
          setSelected(true)
        }
      }
      else {
        formValues.productsSelected.pop(item);
        setSelected(false);
      }
    }

    return (
      <tr className={selected ? "bg-lightblue" : null}>
        <td>
          <td>
            <span className="fw-normal">
              {(() => {
                // Check if productJson is present and non-empty
                if (!attestationDetails?.productJson) {
                  console.log("Empty or undefined productJson:", attestationDetails?.id);
                  return "";
                }

                try {
                  const parsed = JSON.parse(attestationDetails.productJson);
                  return parsed?.product?.productName || "";
                } catch (error) {
                  console.log("Failed to parse productJson:", attestationDetails.id, error);
                  return "";
                }
              })()}
            </span>
          </td>
          <span className="fw-normal">
            {attestationDetails?.uniqueCode}<br />
            {attestationDetails?.productJson
              ? JSON.parse(attestationDetails.productJson)?.product?.productName
              : ""}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {attestationDetails?.productJson
              ? JSON.parse(attestationDetails.productJson)?.product?.ean
              : ""}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {batchNumbers}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {getSADate(validFrom)}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {getSADate(validTo)}
          </span>
        </td>
        <td className="text-right px-4" >
          {selected ?
            (<>


              <Button variant="primary" size="sm" className="text-center"
                onClick={() => selectItem(ReadDataToProductItem(id, certificateNumberList, dailyAttestationItemId, attestationRequest, batchNumberRange, batchNumbers, attestationDetails, validFrom, validTo))}>
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            </>
            )
            :
            (<>

              <Button variant="light" size="sm" className="text-center"
                onClick={() => selectItem(ReadDataToProductItem(id, certificateNumberList, dailyAttestationItemId, attestationRequest, batchNumberRange, batchNumbers, attestationDetails, validFrom, validTo))}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </>
            )
          }
        </td>
      </tr>
    );
  };

  return (
    <>
      <Preloader component={true} show={!loaded} />
      <Table hidden={!loaded || attestationRequests == 0} responsive hover className="user-table align-items-center">
        <thead>
          <tr>
            <th className="border-bottom">URN / Product name</th>
            <th className="border-bottom">EAN</th>
            <th className="border-bottom">Batch numbers</th>
            <th className="border-bottom">Production Date From</th>
            <th className="border-bottom">Production Date To</th>
            <th className="border-bottom"></th>
          </tr>
        </thead>
        <tbody>
          {attestationRequests?.map(t =>
            <TableRow key={`dailyattestation-${t.id}`}
              {...t}
              // dailyAttestationItemId={t.dailyAttestationItemId}
              selections={formValues.productsSelected}
            />)}
        </tbody>
      </Table>
      {loaded && attestationRequests?.length == 0 && (
        <NoResults name="attestation requests" />
      )}
    </>
  );
};


export default DailyAttestationProductListTable;