import { faClone, faFileContract } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import PermissionsCheck from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Routes } from "../../../../routes";
import BlobService from "../../../services/BlobService";
import DailyAttestationDataService from "../../../services/DailyAttestationService";
import { DoesContainCertificateNumber as CheckCertificateNumberInAttestation } from './Forms/FormComponents/DailyAttestationFunctions';
import formModel from "./Forms/Models/DailyAttestationModel";

const DailyAttestationViewPage  = (props) => {
const { formId, formField } = formModel;
    const [dailyAttestationId] = useState(props.match.params.id);
    const [dailyAttestation, setDailyAttestation] = useState([]);
    const history = useHistory();

    const openBlobPdf = (filename) => {
        BlobService.openBlobPdf(filename);
    }

    const _cloneDailyAttestation = (id) => {
        window.open(Routes.DailyAttestationClonePage.path.replace(":id", id),"_self");
    };
  
    useEffect(()=>{ 
        DailyAttestationDataService.getById(dailyAttestationId)
        .then(response => {
            setDailyAttestation(response.data);
        })
        .catch(e => {
            console.log(e);
        });
      },[]);

    return (
        <div>
            <BreadcrumbNav 
                data={{
                    item1 : {name:'Attestations', link: Routes.AttestationDashboard.path},
                    item2 : {name: "Viewing " + dailyAttestation.uniqueCode}}
                }
            />
            <>
            <Row>
                <Col >
                    <h2 id="tabelLabel" >{dailyAttestation.dailyAttestationName}</h2>
                </Col>
            </Row>
            <Row className="">
                <Col>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col>
                                    <h4 className="mb-0">Daily Attestation Details</h4>
                                </Col>
                                <Col className="text-end" lg={10} xl={8}>
                                    
                                    <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.dailyattestations.create]}>
                                        <Button onClick={()=> _cloneDailyAttestation(dailyAttestation.id)} size="md"  variant="light"> 
                                            <FontAwesomeIcon icon={faClone} className="me-2" />
                                            <span>Clone</span>
                                        </Button>
                                        &nbsp;
                                    </PermissionsCheck>
                                
                                    <Button 
                                        variant="light" size="md" className="d-print-none"
                                        onClick={()=>openBlobPdf(dailyAttestation.signedDocument?.filename)}>
                                        <FontAwesomeIcon icon={faFileContract} className="me-2" />
                                        <span>Signed Declaration</span>
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <ul className="list-unstyled">
                                <li>
                                    <p>
                                        <small className="d-block">{formField.trackingRef.label}:</small>
                                        <label>{dailyAttestation.uniqueCode!==null && dailyAttestation.uniqueCode!==""?
                                        (dailyAttestation.uniqueCode):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">{formField.deliveryDate.label}:</small>
                                        <label>{dailyAttestation.deliveryDate!==null && dailyAttestation.uniqueCode!==""?
                                        moment(dailyAttestation.deliveryDate).format("Do MMM YYYY") : "N/A"}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">{formField.commercialNumber.label}:</small>
                                        <label>{dailyAttestation.commercialNumber!==null ?
                                        (dailyAttestation.commercialNumber) : "N/A"}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">New delivery date to export depot: </small>
                                        <label>{dailyAttestation.validFrom!==null && dailyAttestation.validFrom!==""?
                                        moment(dailyAttestation.validFrom).format("Do MMM YYYY") : "N/A"}</label>
                                    </p>
                                </li>
                                <li>
                                    <h5 className="mt-5 mb-3">{formField.productsSelected.label}:</h5>
                                     {dailyAttestation.dailyAttestationItems?.sort((a, b) => a.order - b.order).map((x, i)=>(
                                        <div className="mb-4">
                                            <label className="d-block">Product {i + 1} - {formField.productName.label}: {x.productName}:</label>
                                            <ul>
                                                <li>{formField.uniqueCode.label}: {x.uniqueCode} |
                                                {x.requestFilename !== "" &&
                                                <a className="d-inline mx-2 fw-bold d-print-none"
                                                    onClick={()=>openBlobPdf(x.requestFilename)}>
                                                    <span>Manufacturer declaration</span>
                                                </a>
                                                }|
                                                {x.responseFilename !== "" &&
                                                <a className="d-inline mx-2 fw-bold d-print-none"
                                                    onClick={()=>openBlobPdf(x.responseFilename)}>
                                                    <span>Vet declaration</span>
                                                </a>
                                                }
                                                </li>
                                                {x.batchNumbers == "" || x.batchNumbers == null?
                                                    <li>{formField.batchNumberRange.label}:&nbsp;
                                                        {x.batchNumbersDisplay} 
                                                    </li>
                                                    :
                                                    <li>
                                                        {formField.batchNumbers.label}:&nbsp;
                                                        {x.batchNumbersDisplay}
                                                    </li>
                                                }
                                                <li>{x.weightType == 1? formField.numberOfUnits.label : formField.numberOfCases.label }: {x.numberOfPackages}</li>
                                                <li>{formField.netWeight.label}: {x.netWeight}</li>
                                                <li>{formField.grossWeight.label}: {x.grossWeight}</li>
                                                <li>{formField.netWeightTotal.label}: {x.netWeightTotal}</li>
                                                <li>{formField.grossWeightTotal.label}: {x.grossWeightTotal}</li>
                                                {CheckCertificateNumberInAttestation(x,[8383,8384,8350])&&
                                                    <>
                                                        {/* <li>{formField.cuttingPlantApprovalNumbers.label}:&nbsp;
                                                        {x.cuttingPlantApprovalNumbers === "" ? 
                                                            x.establishmentsCuttingPlants?.length> 0? 
                                                                x.establishmentsCuttingPlants?.map(x=>x.approvalNumber)?.join(", ") 
                                                            : 
                                                                "N/A" 
                                                        : 
                                                            x.cuttingPlantApprovalNumbers
                                                        }
                                                        </li> */}
                                                        <li>{formField.abattoirs.label}:&nbsp;
                                                            {x.abattoirs === "" ? 
                                                                x.establishmentsSlaughterhouses?.length> 0? 
                                                                    x.establishmentsSlaughterhouses?.map(x=>x.approvalNumber)?.join(", ") 
                                                                : 
                                                                    "N/A" 
                                                            : 
                                                                x.abattoirs
                                                            }
                                                        </li>
                                                       </>
                                                }
                                                {CheckCertificateNumberInAttestation(x,[8390,8383,8350,8384,8396])&&
                                                    <li>{formField.originAnimal.label}: {x.originAnimal}</li>
                                                }

                                                {/* <li>{formField.dateCollectionProduction.label}: {x.dateCollectionProduction!==null && x.dateCollectionProduction!==""? moment(x.dateCollectionProduction).format("Do MMM YYYY") : "N/A"}</li> */}
                                                <li>
                                                   {formField.dateCollectionProduction.label}:&nbsp;
                                                   {String(x.isDateCollectionProductionMulti) == "true"?
                                                        moment(x.dateCollectionProductionStart)?.format("DD MMMM YY")
                                                        + " to " +
                                                        moment(x.dateCollectionProductionEnd)?.format("DD MMMM YY")
                                                    :
                                                        moment(x.dateCollectionProductionStart)?.format("DD MMMM YY")
                                                    }
                                                </li>
                                                <li>{formField.notes.label}: {x.notes}</li>
                                                
                                                {CheckCertificateNumberInAttestation(x,[8350])&&
                                                    <li>{formField.dairyEstablishments.label}:&nbsp;
                                                    {x.dairyEstablishments === "" ? 
                                                        x.establishmentsDairy?.length> 0? 
                                                            x.establishmentsDairy?.map(x=>x.approvalNumber)?.join(", ") 
                                                        : 
                                                            "N/A" 
                                                    : 
                                                        x.dairyEstablishments
                                                    }
                                                    </li>
                                                }
                                                
                                                {CheckCertificateNumberInAttestation(x,[8350, 8360])&&
                                                    <li>{formField.farmPost.label}: {x.farmPost}</li>
                                                }
                                                
                                                {x.birdFluCheck &&
                                                <li>
                                                    Additional information (product contains poultry):
                                                    <ul>
                                                        <li>{formField.slaughterDates?.label}: 
                                                            {x.slaughterDates?.length>0? 
                                                                x.slaughterDates?.map((dateItem,index) =>(
                                                                <>
                                                                    <span>{index > 0 && ',  '}</span>
                                                                    <span>{moment(dateItem).format("DD/MM/YYYY")}</span>
                                                                </>
                                                                ))
                                                                : 
                                                                ""
                                                            }
                                                        </li>
                                                        <li>{formField.farmPost?.label}: {x.farmPost}</li>
                                                        <li>
                                                            {formField.poultryCuttingPlantApprovalNumbers.label}:&nbsp;
                                                            {x.poultryCuttingPlantApprovalNumbers !== null? x.poultryCuttingPlantApprovalNumbers : "N/A"}
                                                        </li>

                                                        <li>
                                                            {formField.abattoirApprovalNumbers.label}: 
                                                            {x.abattoirApprovalNumbers !== null? x.abattoirApprovalNumbers : "N/A"}
                                                        </li>
                                                        <li>{formField.poultryMeatArrivalDate?.label}:&nbsp;{x.poultryMeatArrivalDate!==null && x.poultryMeatArrivalDate!==""? moment(x.poultryMeatArrivalDate).format("Do MMM YYYY") : "N/A"}</li>
                                                    </ul>
                                                </li>
                                                } 
                                            </ul>
                                        </div>
                                    ))}
                                </li>
                                <li>
                                    <p className=" mt-5">
                                       <small className="text-gray-600 text-italic">Created on {moment(dailyAttestation.createdDate).format("Do MMM YYYY")}</small>
                                    </p>
                                </li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="">
                <Col>
                    <div className="btn-panel left">
                        <Button variant="light" href="/attestations">
                            Back to Attestations
                        </Button>
                    </div>
                </Col>
            </Row>
            </>
            
        </div>
    )

}
export default DailyAttestationViewPage;