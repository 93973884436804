import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Formik, Form } from 'formik';
import FormController from "../../common/FormController";
import CountryService from "../../../services/CountryService";
import ProductHoneyService from "../../../services/ProductHoneyService";
import ProductService from "../../../services/ProductService";
import { MultiSelectField } from "../../common/MultiSelect";
import { Button, Card, Alert, Row, Col } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import ColdStoreSelect from '../../../../components/EstablishmentSelect/ColdStoreSelect';
import ActivityService from '../../../services/ActivityService';
import HoneyEstablishmentSelect from '../../../../components/EstablishmentSelect/HoneyEstablishmentSelect';


const Honey8391AddPage = (props) => {
    const certificateNumber = 8391;
    const [isSubmitting, setSubmitting] = useState(false);
    const { id, attestationId } = useParams();
    const [formVersion] = useState(GetCertificateFormVersion(certificateNumber));
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [countrys, SetCountrys] = useState([]);
    const [apiValues, SetApiValues] = useState([]);

    let title = (editing == true ? "Edit " : "New") + " Honey Information (#8391-For Honey products only)"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Honey 8391', link: Routes.Honey8391AddPage.path },
    };
    const initialValues = {
        productId: props.match.params.id,
        honeyCountryOrigin: apiValues?.honeyCountryOrigin ?? [],
        honeyEstablishments: apiValues?.honeyEstablishments ?? [],
        productType: apiValues?.productType ?? '',
        species: apiValues?.species ?? '',
        treatmentType: apiValues?.treatmentType ?? '',
        coldStore: apiValues?.coldStore ?? '',
        coldStoreEstablishments: apiValues?.coldStoreEstablishments ?? [],
    }

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";

    const treatments = [
        { value: "", key: "" },
        { value: "Ultrasonication", key: "Ultrasonication" },
        { value: "Homogenisation", key: "Homogenisation" },
        { value: "Ultrafiltration", key: "Ultrafiltration" },
        { value: "Pasteurisation", key: "Pasteurisation" },
        { value: "No thermal treatment", key: "No thermal treatment" },
    ]
    const productTypes = [
        { value: "", key: "" },
        { value: "Honey", key: "Honey" },
        { value: "Apiculture products", key: "Apiculture products" },
    ]


    //Validation
    const validationSchema = Yup.object().shape({
        productType: Yup.string().trim().required(validationMsgRequired),
        honeyCountryOrigin: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        treatmentType: Yup.string().trim().required(validationMsgRequired),
        honeyEstablishments: Yup.array().min(1, validationMsgAtLeastOne)
        //     .required(validationMsgRequired),
    });

    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            submitData.version = formVersion;

            console.log(submitData);

            if (apiValues.length === 0) {
                ProductHoneyService.create(submitData)
                    .then(async response => {
                        console.log(response.data);
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Honey 8391',
                            8391,
                            'Create',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            else {
                ProductHoneyService.update(productId, values)
                    .then(async response => {
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Honey 8391',
                            8391,
                            'Update',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            console.log(JSON.stringify(values, null, 2));
        }, 1000);
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);



    useEffect(() => {
        ProductHoneyService.getByProductId(productId)
            .then(response => {
                if (response.data == "") {
                    return;
                }
                console.log(response.data);
                SetApiValues(response.data);
                setEditing(true);
                setReady(true);
                console.log("init values:" + apiValues);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductService.getById(productId)
            .then(response => {
                SetProduct(response.data.data);
                setIsGEFS(response.data.data.isGEFS);
            }, [])
            .catch(e => {
                console.log(e);
            });
        CountryService.getAll()
            .then((_countryResponse) => {
                SetCountrys(_countryResponse.data);
            })
            .finally(() => {
            });
    }, []);

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);


    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <>
                    <SubmissionPage
                        productId={productId}
                        attestationId={attestationId}
                        isGEFS={isGEFS}
                        errors={errors}
                        title={(editing ? "Edited" : "Completed") + " Honey 8391 Form"}
                        message={`Honey information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                    />

                </>

            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        {/* <PrettyPrintJson data={props.values} />   */}
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />

                                        <ul className="list-unstyled list-clauses" >
                                            <FormController
                                                control="select"
                                                label="Product Type"
                                                name="productType"
                                                options={productTypes}
                                                isrequired="true"
                                                col={4}
                                            />
                                            <label className='mt-3 mb-2' > Species <span className='text-gray-600 text-italic'> Please remove the default and use commas (,) to list multiple Species</span> </label>
                                            <FormController
                                                control="input"
                                                type="text"
                                                name="species"
                                                placeholder="Apis Mellifera"
                                                col={4}
                                            />
                                            <MultiSelectField
                                                id='honeyCountryOrigin'
                                                name='honeyCountryOrigin'
                                                label="Originates in"
                                                placeholder="Select fields"
                                                options={countrys}
                                                defaultValues={props.values?.honeyCountryOrigin}
                                                getOptionValue={option => option.id}
                                                getOptionLabel={option => option.countryName}
                                                value={props.values.honeyCountryOrigin}
                                                isMulti={true}
                                                onChange={props.setFieldValue}
                                                onBlur={props.setFieldTouched}
                                                touched={props.touched.honeyCountryOrigin}
                                                error={props.errors.honeyCountryOrigin}
                                                isClearable={true}
                                                isrequired="true"
                                                backspaceRemovesValue={true}
                                                col={4}
                                            />
                                            <Row>
                                                <Col xs={12} sm={6} lg={6}>
                                                    <HoneyEstablishmentSelect
                                                        name="honeyEstablishments"
                                                        selectedFieldName="honeyEstablishments"
                                                        setEstablishmentSelected={props.setFieldValue}
                                                        selections={props.values.honeyEstablishments}
                                                        label="Honey establishment(s)"
                                                        isMulti={true}
                                                        countries={props.values.honeyCountryOrigin.map(x => x.isoCode).map((str) => str.replace(/-(0|1|2|3|4|5)/g, ''))}
                                                        certificateNumber={certificateNumber}
                                                        getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                        getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                        isRequired={true}
                                                        readOnly={readOnly}
                                                    />
                                                </Col>
                                            </Row>
                                            <FormController
                                                control="select"
                                                label="Treatment Type"
                                                name="treatmentType"
                                                options={treatments}
                                                isrequired="true"
                                                col={4}
                                            />
                                            <Row>
                                                <Col xs={12} sm={6} lg={6}>
                                                    <ColdStoreSelect
                                                        name="coldStoreEstablishments"
                                                        selectedFieldName="coldStoreEstablishments"
                                                        setEstablishmentSelected={props.setFieldValue}
                                                        selections={props.values.coldStoreEstablishments}
                                                        label="Cold Stores"
                                                        isMulti={true}
                                                        countries={props.values.honeyCountryOrigin.map(x => x?.isoCode)}
                                                        certificateNumber={[certificateNumber]}
                                                        getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                        getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                        isRequired={false}
                                                        readOnly={readOnly}
                                                    />
                                                </Col>
                                            </Row>
                                        </ul>
                                        <div className="form-group">
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />

                                        </div>

                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default Honey8391AddPage;