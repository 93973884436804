import React, { useState, useEffect, useRef } from 'react';
import { Routes } from "../../../routes";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import FormSubmission from "../common/FormSubmission";
import { Formik, Form } from 'formik';
import FormController from "../common/FormController"
import { Button, Card, Col, Row, Modal, CloseButton } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import moment from "moment"
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes } from '../../../auth/PermissionsMap';
import CertificateBankService from '../../services/CertificateBankService';
import CertificateNumberService from '../../services/CertificateNumberService';
import CertificateLanguageService from '../../services/CertificateLanguageService';
import CountryService from "../../services/CountryService";
import PlatformDataService from "../../services/PlatformService";
import Progress from "../../../components/Progress";
import * as Yup from 'yup';

const CertificateUpload = (props) => {

    const [progressMessage, setProgressMessage] = useState('');
    const [showModalGenerating, setShowModalGenerating] = useState(false);
    const [generatingPercentage, setGeneratingPercentage] = useState(null);
    const [generateTime, setGenerateTime] = useState('');
    const [uploadedFiles, setCertificateBank] = useState([]);
    const [productId] = useState(props.productId);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [running, setRunning] = useState(false);
    const [ready, setReady] = useState(false);
    const [errors, setErrors] = useState(null);
    const [certificateNumbers, SetCertificateNumbers] = useState([]);
    const [certificateLanguages, SetCertificateLanguages] = useState([]);
    const [destinationCountries, SetDestinationCountries] = useState([]);
    const [platforms, SetPlatforms] = useState([]);
    const [uploadedFileCount, setUploadedFileCount] = useState(0);
    const [title] = useState("Upload Certificate Pack");

    const breadcrumbData = {
        item1: { name: 'Certificate Bank', link: Routes.CertificateListingPage.path },
        item2: { name: "Upload Certificates", link: '#' }
    };

    const isJanuary = new Date().getMonth() === 0;

    const initialValues = {
        certificateNumberId: '',
        languageCode: '',
        destinationCountry: '',
        platform: '',
        year: moment().format('YY'),
        serialNumber1: moment().format('YY'),
        serialNumber2: '',
        serialNumber3: '',
        zipFile: null,
        uploadType: 0
    }

    const validationSchema = Yup.object().shape({
        zipFile: Yup.mixed()
            .test("required", "You need to provide a file", (file) => {
                if (file) return true;
                return false;
            }),
        languageCode: Yup.object().nullable().required("Must choose a language code"),
        destinationCountry: Yup.object().nullable().required("Must choose a destination country"),
        platform: Yup.object().nullable().required("Must choose a platform"),
        certificateNumberId: Yup.object().required("Must enter certificate"),
        uploadType: Yup.number().required("Must enter upload type"),
        year: Yup.object().nullable()
            .when("uploadType", (uploadType, schema) => {
                return uploadType === 2 && isJanuary
                    ? schema.required("Must choose a year")
                    : schema.notRequired();
            }),
        serialNumber1: Yup.string()
            .when("uploadType", {
                is: 2,
                then: Yup.string().required(" ")
                //.required("Must enter first part of serial number")
            }),
        serialNumber2: Yup.string()
            .when("uploadType", {
                is: 2,
                then: Yup.string().required(" ")
                //.required("Must enter second part of serial number")
            }),
        serialNumber3: Yup.string()
            .when("uploadType", {
                is: 2,
                then: Yup.string().required(" ")
                //.required("Must enter third part of serial number")
            }),

    });

    //Modal - Close
    const handleCloseModal = () => {
        setShowModalGenerating(false);
    }

    //Submit
    function onSubmit(values, { setSubmitting, resetForm }) {
        setGeneratingPercentage(0);
        setShowModalGenerating(true);
        setRunning(true);

        const formData = new FormData();
        let certificateSerialNumber = isJanuary
            ? `${values.year.value}/${values.serialNumber2}/${values.serialNumber3}`
            : `${values.serialNumber1}/${values.serialNumber2}/${values.serialNumber3}`;

        formData.append('certificateSerialNumber', certificateSerialNumber);
        formData.append('uploadType', values.uploadType);
        formData.append('languageCode', values.languageCode.value);
        formData.append('destinationCountry', values.destinationCountry.value);
        formData.append('platform', values.platform.value);

        //Zip file
        if (values.uploadType == 1) {
            for (let i = 0; i <= values.zipFile.length; i++) {
                if (values.zipFile[i] !== undefined) {
                    formData.append('zipFile', values.zipFile[i]);
                }
            }
            formData.append('pdfFiles', null);
        }

        //Single PDF upload
        if (values.uploadType == 2) {
            for (let i = 0; i <= values.zipFile.length; i++) {
                formData.append(`pdfFiles`, values.zipFile[i]);
            }
            formData.append('zipFile', null);
            formData.append('serialNumber', `${values.serialNumber1}/${values.serialNumber2}/${values.serialNumber3}`);

        }

        formData.append('certificateNumber', values.certificateNumberId.value);

        //Post data to service
        CertificateBankService.upload(formData)
            .then(response => {
                setGeneratingPercentage(100);
                setTimeout(() => {
                    handleCloseModal();
                    setRunning(false);
                    setSubmitting(false);
                    setFormSubmitted(true);
                }, 2000);
            })
            .catch(e => {
                handleCloseModal();
                setRunning(false);
                setSubmitting(false);
                setFormSubmitted(true);
                setErrors(e.response.data);
                console.log(e);
            });
        // if(errors !==null){
        //     // setErrorList(errors);
        //     setGenerating(false);
        //     handleCloseModal();
        //     setRunning(false);
        // }
    }

    //Get initial data for form
    function retrieveFormInitialData() {

        //Number Data
        const getCertificateNumbers = new Promise((resolve, reject) => {
            resolve(CertificateNumberService.getAll()
                .then((response) => {
                    const options = response.data.map(d => ({
                        "value": d.number.toString(),
                        "label": d.name,
                        "active": d.active,
                    })).sort((a, b) => a.label.localeCompare(b.label));
                    SetCertificateNumbers(options.filter(x => x.active));
                })
                .catch((e) => {
                    console.log(e);
                })
            );
        });
        //Language data
        const getCertificateLanguages = new Promise((resolve, reject) => {
            resolve(CertificateLanguageService.getAll(productId)
                .then(response => {
                    const options = response.data.map(d => ({
                        "value": d.code.toString(),
                        "label": d.name,
                        "active": d.active,
                    })).sort((a, b) => a.label.localeCompare(b.label));
                    const firstItem = "EN";
                    options.sort((x, y) => { return x.value === firstItem ? -1 : y.value === firstItem ? 1 : 0; });
                    console.log(options);
                    SetCertificateLanguages(options);
                })
                .catch(e => {
                    console.log(e);
                })
            );
        });

        const getCountryData = new Promise((resolve, reject) => {
            resolve(CountryService.getAll()
                .then((response) => {
                    const options = response.data.map(d => ({
                        "value": d.id,
                        "label": d.countryName
                    })).sort((a, b) => a.label.localeCompare(b.label));
                    const firstItem = "Great Britain";
                    options.sort((x, y) => { return x.label === firstItem ? -1 : y.label === firstItem ? 1 : 0; });
                    SetDestinationCountries(options);
                })
                .catch((e) => {
                    console.log(e);
                })
            );
        });
        //Platform data
        const getPlatformData = new Promise((resolve, reject) => {
            resolve(PlatformDataService.getAll(productId)
                .then(response => {
                    const options = response.data.map(d => ({
                        "value": d.id,
                        "label": d.platformName
                    })).sort((a, b) => a.label.localeCompare(b.label));
                    SetPlatforms(options);
                })
                .catch(e => {
                    console.log(e);
                })
            );
        });
        Promise.all([getCertificateNumbers, getCertificateLanguages, getPlatformData, getCountryData
        ]).then((responses) => {
            setReady(true);
        });
    }

    //Effect - Get data on initial load
    useEffect(() => {
        retrieveFormInitialData();
    }, []);

    //Effect - Run when uploading is occuring
    useEffect(() => {
        if (running) {
            var seconds = (7 * uploadedFileCount);
            var duration = "";
            if (seconds > 59) {
                var m = moment.utc(moment.duration(seconds, "seconds").asMilliseconds());
                var roundUp = m.second() || m.millisecond() ? m.add(1, 'minute').startOf('minute') : m.startOf('minute');
                duration = roundUp.format("m") + " minutes"
            }
            else {
                duration = moment.utc(moment.duration(seconds, "seconds").asMilliseconds()).format("s") + " seconds"
            }
            var increment = (0.001 * uploadedFileCount);
            setGenerateTime(duration);
            const timeout = setTimeout(() => {
                setGeneratingPercentage((prev) => prev + increment);
            }, 1);
            if (generatingPercentage > 1 && generatingPercentage <= 80) {
                setProgressMessage("Uploading the certificate(s)...");
            }
            if (generatingPercentage > 80 && generatingPercentage <= 100) {
                setProgressMessage("Finishing up...");
            }
            if (generatingPercentage >= 99) {
                clearTimeout(timeout);
            }
            return () => (clearTimeout(timeout));
        }
    }, [running, generatingPercentage]);


    //Remove 
    const handleRemove = (id) => {
        CertificateBankService.remove(id)
            .then(response => {
                retrieveFormInitialData();
            })
            .catch(e => {
                console.log(e);
            });
    };

    const FileRow = ({ file }) => {

        function getLinkText(file) {
            var fileName = file.filename.substring(37);
            return (
                <div className='text'>
                    <a href={file.fileUrl} >
                        <FontAwesomeIcon icon={faFile} className={`mx-auto color-warning `} />
                        <span className="d-block fs-7">{fileName}</span>
                        <small><i>{formatBytes(file.size, null)}. Added {formattedDate(file.CreatedDate)} by {file.userFullName}</i></small>
                    </a>
                </div>
            );
        }

        function formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
        }

        function formattedDate(date) {
            var newDate = moment(date).format("D/MM/YY");
            if (newDate === "Invalid date") {
                return "";
            }
            return newDate;
        }

        return (


            <Row>
                <Col sm={10}>
                    {getLinkText(file)}
                </Col>
                <Col sm={2}>
                    <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.productdetail.update]}>
                        <CloseButton onClick={() => { handleRemove(file.id) }} />
                    </PermissionsCheck>
                </Col>
            </Row>
        )
    };

    const files = uploadedFiles.map(file => (
        <li key={file.path} className="dropzone-upload" >
            <FileRow file={file} />
        </li>
    ));
    const uploadTypeChoice = [
        { group: "uploadTypeChoice", fieldname: "uploadTypeChoice1", value: 1, key: "Zip file from gov.uk" },
        { group: "uploadTypeChoice", fieldname: "uploadTypeChoice2", value: 2, key: "Single PDF file(s)" },
    ]

    const currentYear = new Date().getFullYear();
    const formattedCurrentYear = String(currentYear).slice(2);
    const formattedPreviousYear = String(currentYear - 1).slice(2);
    const years = [{ label: formattedCurrentYear, value: formattedCurrentYear }, { label: formattedPreviousYear, value: formattedPreviousYear }];


    return (
        <>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>
            {formSubmitted ? (
                <FormSubmission
                    errors={errors}
                    title="Added Certificates"
                    message="This certificates has been successfully added to the bank."
                    successButtonUrl={Routes.CertificateListingPage.path}
                    successButtonName="Back to Certificates"
                />
            )
                :
                (
                    <Card border="light" className="h-100 bg-white shadow-sm">
                        <Card.Body>

                            <Formik
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                initialValues={initialValues}
                                onSubmit={onSubmit}>
                                {(props, isSubmitting) => (
                                    <Form>
                                        <Row className="mt-0">
                                            <Col md={8}>
                                                <FormController
                                                    control="multiselect"
                                                    label="Certificate number"
                                                    name="certificateNumberId"
                                                    placeholder="Select a certificate number"
                                                    options={certificateNumbers}
                                                    isrequired="true"
                                                    isMulti={false}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.certificateNumberId}
                                                    error={props.errors.certificateNumberId}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>

                                                <FormController
                                                    control="multiselect"
                                                    label="Certificate Language"
                                                    name="languageCode"
                                                    placeholder="Select a language"
                                                    options={certificateLanguages}
                                                    isrequired="true"
                                                    isMulti={false}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.languageCode}
                                                    error={props.errors.languageCode}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormController
                                                    control="multiselect"
                                                    label="Destination Country"
                                                    name="destinationCountry"
                                                    placeholder="Select a Country"
                                                    options={destinationCountries}
                                                    isrequired="true"
                                                    isMulti={false}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.languageCode}
                                                    error={props.errors.languageCode}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormController
                                                    control="multiselect"
                                                    label="Platform"
                                                    name="platform"
                                                    placeholder="Select a Platform"
                                                    options={platforms}
                                                    isrequired="true"
                                                    isMulti={false}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.languageCode}
                                                    error={props.errors.languageCode}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormController
                                                    control="radio"
                                                    label="Which type of file are you uploading?"
                                                    name="uploadType"
                                                    options={uploadTypeChoice}
                                                    isrequired="true"
                                                />

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={8} xl={5}>
                                                {props.values.uploadType == 2 &&
                                                    <Row>
                                                        <Col lg={12}>
                                                            <label className="required mt-3 mb-2">Certificate Serial Number
                                                            </label>
                                                            <small className="text-gray-600 text-italic">&nbsp;&nbsp;
                                                                Found on I.2 on Export Health Certificate
                                                            </small>

                                                        </Col>
                                                        {isJanuary ? (
                                                            <Col xl={2}>
                                                                <FormController
                                                                    control="multiselect"
                                                                    name="year"
                                                                    placeholder="Year"
                                                                    options={years}
                                                                    isrequired="true"
                                                                    isMulti={false}
                                                                    onChange={props.setFieldValue}
                                                                    onBlur={props.setFieldTouched}
                                                                    touched={props.touched.languageCode}
                                                                    error={props.errors.languageCode}
                                                                />
                                                            </Col>
                                                        )
                                                            :
                                                            (
                                                                <Col xl={2}>

                                                                    <FormController
                                                                        control="input"
                                                                        tye="number"
                                                                        name="serialNumber1"
                                                                        disabled={true}
                                                                        className="d-inline"
                                                                        max={2}
                                                                    />
                                                                </Col>

                                                            )}
                                                        <Col xl={1} className="mx-0 p-0 text-center">
                                                            <div className="fs-4">/</div>
                                                        </Col>
                                                        <Col xl={2}>
                                                            <FormController
                                                                control="input"
                                                                tye="number"
                                                                name="serialNumber2"
                                                                className="d-inline"
                                                                max={2}
                                                            />
                                                        </Col>
                                                        <Col xl={1} className="mx-0 p-0 text-center">
                                                            <div className="fs-4">/</div>
                                                        </Col>
                                                        <Col xl={3}>
                                                            <FormController
                                                                control="input"
                                                                tye="number"
                                                                name="serialNumber3"
                                                                className="d-inline"
                                                                max={6}
                                                            />
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mt-4">
                                                    <label className="mb-2">Select certificate ZIP file for upload</label>
                                                    <FormController
                                                        control="multipleFileUpload"
                                                        disabled={props.values.uploadType <= 0}
                                                        allowedFileTypes={props.values.uploadType == 1 ? ".zip" : ".pdf"}
                                                        label="Select certificate zip to upload"
                                                        setUploadedFileCount={setUploadedFileCount}
                                                        name="zipFile"
                                                        multiple={true}
                                                        col={8}
                                                    />
                                                </div>
                                                <div className="form-group mt-3">
                                                    <Button type="submit" disabled={isSubmitting || props.values.zipFile == null}>
                                                        {isSubmitting ? "Please wait..." : "Upload"}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>

                                        {/* //Modal - Start*/}
                                        <Modal size="lg" as={Modal.Dialog} centered show={showModalGenerating}>
                                            <Modal.Body>
                                                <div className='d-flex align-items-center my-5 mt-lg-6 mb-lg-5'>
                                                    <div className="container">
                                                        <div className="d-flex align-items-center justify-content-center col-12">
                                                            <div className='text-center p-4 p-lg-5 w-100 fmxw-500'>
                                                                <h2 className="mb-5">Uploading Certificate(s)</h2>
                                                                <p>
                                                                    This will take an estimated {generateTime} to upload. You can either remain on this screen and wait, or return to the certificate bank later and the process will continue in the background.</p>
                                                                <div className="generateprogress"><Progress value={generatingPercentage} variant="success" label="" size="xl" />
                                                                    <label>{progressMessage}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>

                                    </Form>
                                )}
                            </Formik >


                            {files.length > 0 ? (
                                <ul className="dropzone-uploaded list-unstyled mb-2">
                                    {files.slice(0, 3)}
                                </ul>
                            )
                                :
                                (
                                    <div className="text-center pt-4">
                                    </div>
                                )}

                            {files.length > 0 && (
                                <ul className="dropzone-uploaded list-unstyled">
                                    {files}
                                </ul>
                            )}
                        </Card.Body>
                    </Card>
                )}
        </>
    );
};

export default CertificateUpload;