import CompositeService from "../../services/CompositeService";
import ProductDairyService from "../../services/ProductDairyService";
import ProductMeatService from "../../services/ProductMeatService";
import ProductFisheryService from "../../services/ProductFisheryService";
import { Routes } from "../../../routes";
import ProductMeatPreparationService from "../../services/ProductMeatPreparationService";
import ProductHighlyRefinedService from "../../services/ProductHighlyRefinedService";
import ProductGelatineService from "../../services/ProductGelatineService";
import ProductWildGameService from "../../services/ProductWildGameService";
import ProductCollagenService from "../../services/ProductCollagenService";
import ProductHoneyService from "../../services/ProductHoneyService";
import ProductVitaminDService from "../../services/ProductVitaminDService";
import ProductCasingService from "../../services/ProductCasingService";
import ProductDogChewsService from "../../services/ProductDogChewsService";
import ProductPorcineService from "../../services/ProductPorcineService";
import ProductOvineService from "../../services/ProductOvineService";
import ProductBovineService from "../../services/ProductBovineService";
import ProductEggService from "../../services/ProductEggService";
import ProductPoultryService from "../../services/ProductPoultryService";
import ProductStorageService from "../../services/ProductStorageService";

export const certificateData = {
    8361: {}, 
    8350: {}, 
    8383: {}, 
    8384: {}, 
    8399: {}, 
    8390: {}, 
    8391: {},
    8396: {},
    8468: {}, 
    8392: {},
    8367: {},
    8393: {},
    8325: {},
    8370: {},
    8369: {},
    8368: {},
    8360: {},
    8461: {},
    8371: {}
};

export const certificateNumbers = {
    8361: false,
    8350: false,
    8384: false,
    8383: false,
    8399: false,
    8390: false,
    8391: false,
    8396: false,
    8468: false,
    8392: false,
    8367: false,
    8393: false, 
    8325: false, 
    8370: false,
    8369: false,
    8368: false,
    8360: false,
    8461: false,
    8371: false
};

export const certificateVersions = [
    {number: 8361, version: 2.0},
    {number: 8350, version: 3.2},
    {number: 8384, version: 2.0},
    {number: 8383, version: 2.1},
    {number: 8399, version: 2.1},
    {number: 8390, version: 3.0},
    {number: 8391, version: 3.1},
    {number: 8396, version: 2.0},
    {number: 8468, version: 3.1},
    {number: 8392, version: 2.0},
    {number: 8367, version: 2.0},
    {number: 8393, version: 2.0},
    {number: 8325, version: 2.0},
    {number: 8370, version: 2.0},
    {number: 8369, version: 2.0},
    {number: 8368, version: 2.0},
    {number: 8360, version: 2.0},
    {number: 8461, version: 1.0},
    {number: 8371, version: 2.0}
];

export const certificateServices = [
    {number: 8350, service: CompositeService},
    {number: 8468, service: ProductDairyService},
    {number: 8361, service: ProductFisheryService},
    {number: 8384, service: ProductMeatService},
    {number: 8383, service: ProductMeatPreparationService},
    {number: 8399, service: ProductHighlyRefinedService},
    {number: 8390, service: ProductGelatineService},
    {number: 8391, service: ProductHoneyService},
    {number: 8396, service: ProductCollagenService},
    {number: 8392, service: ProductVitaminDService},
    {number: 8367, service: ProductWildGameService},
    {number: 8393, service: ProductCasingService},
    {number: 8325, service: ProductDogChewsService},
    {number: 8370, service: ProductPorcineService},
    {number: 8369, service: ProductOvineService},
    {number: 8368, service: ProductBovineService},
    {number: 8360, service: ProductEggService},
    {number: 8461, service: ProductStorageService},
    {number: 8371, service: ProductPoultryService}
]

export const certificateRoutes = [
    {number: "8350", GEFS: Routes.Composite8350.path, NonGEFS:Routes.Composite8350NonGEFS.path},
    {number: "8468", GEFS: Routes.Dairy8468AddPage.path, NonGEFS:Routes.Dairy8468AddPageNonGEFS.path},
    {number: "8361", GEFS: Routes.Fishery8361AddPage.path, NonGEFS:Routes.Fishery8361AddPageNonGEFS.path},
    {number: "8384", GEFS: Routes.Meat8384AddPage.path, NonGEFS:Routes.Meat8384AddPageNonGEFS.path},
    {number: "8383", GEFS: Routes.MeatPreparation8383AddPage.path, NonGEFS:Routes.MeatPreparation8383AddPageNonGEFS.path},
    {number: "8399", GEFS: Routes.HighlyRefined8399AddPage.path, NonGEFS:Routes.HighlyRefined8399AddPageNonGEFS.path},
    {number: "8390", GEFS: Routes.Gelatine8390AddPage.path, NonGEFS:Routes.Gelatine8390AddPageNonGEFS.path},
    {number: "8391", GEFS: Routes.Honey8391AddPage.path, NonGEFS:Routes.Honey8391AddPageNonGEFS.path},
    {number: "8396", GEFS: Routes.Collagen8396AddPage.path, NonGEFS:Routes.Collagen8396AddPageNonGEFS.path},
    {number: "8392", GEFS: Routes.VitaminD8392AddPage.path, NonGEFS:Routes.VitaminD8392AddPageNonGEFS.path},
    {number: "8367", GEFS: Routes.WildGame8367AddPage.path, NonGEFS:Routes.WildGame8367AddPageNonGEFS.path},
    {number: "8393", GEFS: Routes.Casing8393AddPage.path, NonGEFS:Routes.Casing8393AddPageNonGEFS.path},
    {number: "8325", GEFS: Routes.DogChews8325AddPage.path, NonGEFS:Routes.DogChews8325AddPageNonGEFS.path},
    {number: "8370", GEFS: Routes.Porcine8370AddPage.path, NonGEFS:Routes.Porcine8370AddPageNonGEFS.path},
    {number: "8369", GEFS: Routes.Ovine8369AddPage.path, NonGEFS:Routes.Ovine8369AddPageNonGEFS.path},
    {number: "8368", GEFS: Routes.Bovine8368AddPage.path, NonGEFS:Routes.Bovine8368AddPageNonGEFS.path},
    {number: "8360", GEFS: Routes.Egg8360AddPage.path, NonGEFS:Routes.Egg8360AddPageNonGEFS.path},
    {number: "8461", GEFS: Routes.Storage8461AddPage.path, NonGEFS:Routes.Storage8461AddPage.path},
    {number: "8371", GEFS: Routes.Poultry8371AddPage.path, NonGEFS:Routes.Poultry8371AddPageNonGEFS.path}
]
export const certificateGenerationExclusions = [
    {
        number: "8350", 
        propertyNames: [
            "coldStore",
            "dairyAApprovalNumber",
            "cuttingPlant",
            "abattoir",
            "establishmentCodes",
            "eggPercentage",
            "aquaculture",
            "wildOrigin",
            "bivalveMolluscsWildOrigin",
            "bivalveMolluscsAquaculture",
            "version",
            "abattoirEstablishments",
            "abattoirEstablishment",
            "coldStoreEstablishments",
            "cuttingPlantEstablishments",
            "dairyAapprovalNumberEstablishments",
            "dairyAapprovalNumberEstablishment",
            "fisheryEstablishmentCodeEstablishments",
            "fisheryEstablishmentCode",
            "bivalveMolluscsEstablishmentCodeEstablishments",
            "bivalveMolluscsEstablishmentCode",
            "bivalveMolluscsAquaculture",
            "bivalveMolluscsWildOrigin"
        ]
    },
    {
        number: "8468", 
        propertyNames: [
            "coldStore",
            "version",
            "identificationMark",
            "identificationMarkEstablishments",
            "coldStoreEstablishments",
            "rewrappingEstablishments"
        ]
    },
    {
        number: "8361", 
        propertyNames: [
            "coldStore",
            "cuttingPlant",
            "abattoir",
            "aquaculture",
            "wildOrigin",
            "productScientificSpecies",
            "version",
            "identificationMarkEstablishment",
            "coldStoreEstablishments"
        ]
    },
    {
        number: "8384", 
        propertyNames: [
            "coldStore",
            "cuttingPlant",
            "abattoir",
            "version",
            "identificationMark",
            "identificationMarkEstablishments",
            "cuttingPlantEstablishments",
            "coldStoreEstablishments",
            "abattoirEstablishments"
        ]
    },
    {
        number: "8461", 
        propertyNames: [
            "slaughterhouseEstablishments",
            "cuttingPlantEstablishments",
            "version",
            "identificationMarkEstablishment",
            "coldStoreEstablishments",
            "weightType",
            "productScientificSpecies"
        ]
    },
    {
        number: "8383", 
        propertyNames: [
            "coldStore",
            "abattoir",
            "version",
            "identificationMark",
            "identificationMarkEstablishment",
            "coldStoreEstablishments",
            "abattoirEstablishments",
            "storageDate",
            "storageDates"
        ]
    },
    {number: "8399", propertyNames: ["coldStore", "version"]},
    {number: "8390", propertyNames: ["coldStore", "version"]},
    {number: "8391", propertyNames: ["coldStore", "version"]},
    {number: "8396", propertyNames: ["coldStore", "version"]},
    {number: "8392", propertyNames: ["coldStore", "version"]},
    {number: "8367", propertyNames: ["coldStore", "version"]},
    {number: "8393", propertyNames: ["coldStore", "version"]},
    {number: "8325", propertyNames: ["coldStore", "version"]},
    {number: "8370", propertyNames: ["coldStore", "version"]},
    {number: "8369", propertyNames: ["coldStore", "version"]},
    {number: "8368", propertyNames: ["coldStore", "version"]},
    {number: "8360", propertyNames: ["coldStore", "version"]},
    {number: "8461", propertyNames: ["productScientificSpecies","productScientificSpeciesGroupId", "identificationMarkEstablishment", "identificationMarkEstablishments", "slaughterhouseEstablishments", "cuttingPlantEstablishments", "version"]},

    {number: "8371", propertyNames: ["coldStore", "version","animal_2_5_A","animal_2_5_B","animal_2_5_C"]}
]
