import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Spinner } from "@themesberg/react-bootstrap";
import { useFormikContext } from "formik";
import HelloSign from "hellosign-embedded";
import React, { useEffect, useState } from "react";
import SignatureService from "../pages/services/SignatureService";

export default (props) => {
  const {
    document,
    ready,
    signingWaitText = "Please wait whilst we work on your document...",
    buttonText = "Sign document",
  } = props;

  const { submitForm, setFieldValue } = useFormikContext();
  const [isDocSigned, setDocSigned] = useState(false);
  const [signatureRequestId, setSignatureRequestId] = useState("");
  const [isSignLoading, setIsSignLoading] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const [signedDocUrl, setSignedDocUrl] = useState("");

  // Create DropBox Sign / HelloSign client
  const client = new HelloSign({
    clientId: process.env.REACT_APP_HELLOSIGN_CLIENTID,
  });

  //Complete signing process when document signed
  useEffect(() => {
    isDocSigned && handleSignedDocument(signatureRequestId);
  }, [isDocSigned]);

  //Create embedded signing request and enable modal
  function signatureRequest() {
    setIsSignLoading(true);

    //Create embedded signature request
    SignatureService.getSignatureRequest(document)
      .then(async (response) => {
        //Get signature request Id
        let signatureId = response.data.signature_request.signature_request_id;

        //Get individual's signer id for request
        let signerId =
          response.data.signature_request.signatures[0].signature_id;
        setSignatureRequestId(signatureId);

        //Get signature url for signer
        await SignatureService.getSignatureServiceUrl(signerId).then(
          (response) => {
            var signServiceUrl = response.data.replaceAll('"', "");
            client.open(signServiceUrl, {
              clientId: process.env.REACT_APP_HELLOSIGN_CLIENTID,
              testMode: true,
              skipDomainVerification: true,
            });
          }
        );
      })
      .catch((e) => {
        alert(
          "Sorry there was an error signing, please try again or contact us."
        );
        setIsSignLoading(false);
        console.log(e);
      });
  }

  //Upload document to storage
  function handleSignedDocument(signatureReqId) {
    //Get and upload signed document to storage
    SignatureService.uploadSignedDocument(signatureReqId)
      .then((response) => {
        setSignedDocUrl(response.data);
        setFieldValue("signedRequestUrl", response.data);
        setFieldValue("documentId", signatureReqId);
        setComplete(true);
        submitForm();
      })
      .catch((e) => {
        alert("Sorry there was an error, please try again");
        console.log(e);
      });
  }

  //Events

  //Signing event - Signed
  client.on("sign", (data) => {
    client.close();
    setDocSigned(true);
    setIsSignLoading(false);
  });

  //Signing event - Close
  client.on("close", (data) => {
    setDocSigned(false);
    setIsSignLoading(false);
    setComplete(false);
  });

  return (
    <>
      {isDocSigned && !isComplete && (
        <>
          <Spinner
            animation="border"
            variant="primary"
            size="sm"
            className="me-1"
          />
          {signingWaitText}
        </>
      )}
      {!isDocSigned && (
        <>
          {ready ? (
            <>
              <Spinner
                animation="border"
                variant="primary"
                size="sm"
                className="me-1"
              />
              {signingWaitText}
            </>
          ) : (
            <Button
              onClick={signatureRequest}
              variant="tertiary"
              target="_blank"
            >
              {isSignLoading ? (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className="me-1"
                />
              ) : (
                <FontAwesomeIcon icon={faFileSignature} className="me-2" />
              )}
              {buttonText}
            </Button>
          )}
        </>
      )}
      <p className="mt-3">
        <small>
          This signed document meets the requirements of the European Union
          eIDAS (EU No.910/2014), the Uniform Electronic Transactions Act (UETA)
          and the U.S. Electronic Signature in Global and National Commerce Act
          of 2000 (ESIGN) regarding electronic signatures and transmissions.
        </small>
      </p>
    </>
  );
};
