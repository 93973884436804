import React, { useState, useEffect, useRef } from 'react';
import AttestationService from "../../../services/AttestationService"
import DailyAttestationService from "../../../services/DailyAttestationService"
import moment from "moment"
import DailyAttestationAddPage from './DailyAttestationAddPage';
import formInitialValues from './Forms/Models/DailyAttestationValues';
import {ReadDataToProductItem} from './Forms/FormComponents/DailyAttestationProductListFunctions'

const DailyAttestationClonePage = (props) => {
    
  const [cloneId] = useState(props.match.params.id);
  const [cloneData, setCloneData] = useState(null);
  
  useEffect(() => {
    if (!formInitialValues) return; // Just in case it's null or undefined
  
    // Wrap your effect in an async function so we can use await
    const fetchData = async () => {
      try {
        const data = { ...formInitialValues };
        
        const response = await DailyAttestationService.getById(cloneId);
        const res = response.data;
  
        data.commercialNumber = res.commercialNumber;
        data.uniqueCodeClone = res.uniqueCode;
        data.dailyAttestationData = res.dailyAttestationItems;
  
        data.dailyAttestationData.forEach((x) => {
          if (x.isDateCollectionProductionMulti) {
            x.dateCollectionProductionSingle = null;
            x.dateCollectionProductionStart = moment(x.dateCollectionProductionStart);
            x.dateCollectionProductionEnd = moment(x.dateCollectionProductionEnd);
          } else {
            x.dateCollectionProductionSingle = moment(x.dateCollectionProductionStart);
            x.dateCollectionProductionStart = null;
            x.dateCollectionProductionEnd = null;
          }
        });
  
        data.productsSelected = [];
        data.documentId = null;
        data.signedRequestUrl = null;
  
        // 3) Fetch each attestation in parallel, then push into productsSelected
        await Promise.all(
          res.dailyAttestationItems.map(async (x) => {
            try {
              const response2 = await AttestationService.getById(x.attestationId);
              const attestation = response2.data;
              data.productsSelected.push(
                ReadDataToProductItem(
                  attestation.id,
                  attestation.certificateNumberList,
                  x.id,
                  attestation.attestationRequest,
                  attestation.batchNumberRange,
                  attestation.batchNumbers,
                  attestation.attestationDetails,
                  attestation.validFrom,
                  attestation.validTo
                )
              );
            } catch (e) {
              console.error(e);
            }
          })
        );
  
        setCloneData(data);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, [cloneId, formInitialValues]);
  
  return (
        <DailyAttestationAddPage cloneData={cloneData} />
    );
};

export default DailyAttestationClonePage;